import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user-service.service';
import { EntrepriseService } from '../services/entreprise.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  userTotal:number;
  entTotal:number=0;
  favoris:any;
  userName:any;
  weekTotal:number;
  freesearch:string;
  weekNumber;
  weekSauvegarde=0;
  weekRedressement=0;
  weekLiquidation=0;
  week
  year;
  message:string;
  questionType:String="autres";
  data: any;
  emptyPhoto="../../assets/images/avatars/user.jpg"

  collapsed: boolean=true;
  weeks: any=[];
  constructor(private usrService:UserService, private entService:EntrepriseService, private router:Router,
    private storage: LocalStorageService, private confirmationDialogService: ConfirmationDialogService, ) {
      this.data={content:{}}
      this.data.content.profileUrl=this.emptyPhoto;
      let d:any=new Date(Date.now());
      d.setUTCDate(d.getUTCDate() + 3 - (d.getUTCDay()||7));
      // Get first day of year
      let yearStart:any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      // Calculate full weeks to nearest Thursday
      this.weekNumber = Math.ceil(( ( (   d - yearStart) / 86400000) )/7);
       this.year=d.getUTCFullYear()
     }

  async ngOnInit(): Promise<void> {
   /*  if(!this.usrService.tokens){
      this.router.navigate(['/login'])
    }  */

this.usrService.identity().subscribe((data)=>{
  console.log(data.content.firstName);
  this.userName = data.content.firstName;
  this.data=data

  if(!this.data.content.profileUrl){
    this.data.content.profileUrl=this.emptyPhoto


   }else{
    this.data.content.profileUrl=this.usrService.profilePictureUrl+"/"+ this.data.content.profileUrl
   }
   if(!this.data.content.companyUrl){
    this.data.content.companyUrl=this.emptyPhoto
   }else{
    this.data.content.companyUrl=this.usrService.profilePictureUrl+"/"+ this.data.content.companyUrl

   }
})



    this.usrService.getNumberEntity().subscribe((data)=>{
      this.userTotal = data.content;
    });

    this.entService.getNumberEnt().subscribe((data)=>{
      this.entTotal = data.content
    });
    this.entService.getNumberweek().subscribe((data)=>{
      this.weekTotal = data.content
    });
    this.entService.getNumberweekCat().subscribe((data)=>{
      console.log(data)
      this.weeks=data.content;
      this.weekLiquidation = data.content[1].total
      this.weekSauvegarde = data.content[2].total
      this.weekRedressement = data.content[0].total


    });

    this.entService.getLastFavoris().subscribe((data)=>{
       this.favoris = data;

       console.log(this.favoris);
    })
    this.makeAccordion()

  }

  loadFavoris(codeApe,nature,libelleApe,chiffreAffaire,chiffreAffaireMax,ville,effectif,effectifMax,decision,decisionMax,confidentialite){
      console.log(nature)
      let temp=[]
      if(nature){

        temp=nature.split(",");

      }
      this.clearAndSaveCriteria({codeApe:codeApe,nature:temp,libelleApe:libelleApe,chiffreAffaire:chiffreAffaire, chiffreAffaireMax:chiffreAffaireMax,ville:ville,
        effectifMax:effectifMax,decisionMax:decisionMax, confidentialite:confidentialite, effectif:effectif,decision:decision});
     this.router.navigate(['/entreprise/entreprise/search'])
  }

  freeSearch(){
    console.log(this.freesearch)
    this.clearAndSaveCriteria({key:this.freesearch});
       this.router.navigate(['/entreprise/entreprise/freesearch'])
  }

  logout(){
    this.usrService.tokens=null;
    this.storage.clear();
    this.router.navigate(["/login"]);
  }
  makeAccordion(){
    var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}
  }
  contactUs(){
    console.log(this.message)
    this.usrService.contactUs(this.message,this.questionType).subscribe((data)=>{


      this.showtoast()
      this.message=null;
    });

  }
  showtoast() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
  clearAndSaveCriteria(data){
    this.storage.clear('criteria');
    this.storage.store('criteria', data);
  }

  public openConfirmationDialogDel(title: string, message: string,id,i): void {
    this.confirmationDialogService
      .confirm(title, message)
      .then(confirmed => {
        console.log('User confirmed:', confirmed);
        if (confirmed) this.delFavori(id,i);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  delFavori(id,i){
    this.entService.delFavoris(id).subscribe(()=>{
      console.log('delete favoris...')
      console.log(i)
      this.favoris.content.splice(i,1);
    })
  }

   collapse(){
     console.log(this.collapsed)
    this.collapsed= !this.collapsed;
  }
}
