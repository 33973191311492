<div class="row h-100">
  <div class="main-navbar sticky-top ">
    <!-- Main Navbar -->

    <div class="pos-f-tb menu-bar">
      <nav class="navbar navbar-dark menu-button ">
        <a class="lienTop" href="#">
          <div class="contentlienTop">
            <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
          </div>
        </a>
        <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <i   class="fas fa-times" ></i>  
          <i  class="fas fa-bars" ></i>
 
        </button>
      </nav>
      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-white p-4 border-black">
          <ul class="nav nav--no-borders flex-column">             
            
            <li class="nav-item2 "  >
              <span><a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
              </span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " > 
              <span><a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a></span><span class="symbol">&#10095;</span>
            </li>
   
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a routerLink="/login"  class="selected">Se connecter</a></span><span class="symbol">&#10095;</span>
            </li> 
          </ul>      
      </div>
    </div>
  </div>
</div>
  <main class="main-content col">
      <div class="main-content-container container-fluid px-4 my-auto h-100">
        <div class="top-element row">
        
          <div id="logo-container-mobile" class="col-lg-3 col-md-3 logo-container middle">
            <div id="main-logo" class="navbar-header style-light">
              <a href="https://www.kairos-opportunities.com/" class="navbar-brand" data-minheight="20">
                <div class="logo-image main-logo logo-skinnable" data-maxheight="63" style="height: 63px;">
                  <img src="../../assets/images/kairos.png" alt="logo" width="645" height="188" class="img-responsive"></div></a>
            </div>
            <div class="mmb-container"><div class="mobile-additional-icons"></div>
            <div class="mobile-menu-button 1 mobile-menu-button-light lines-button x2"><span class="lines"></span></div></div>
          </div> 
          <div class=" kairos_menu col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <span  >
              <a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
              <a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a>
            <!--    <a href="#">Partenaires</a> -->
              <a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a>
              <a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a>
              <span  class="kairos_login">
                <a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a>
                <a routerLink="/login"  class="selected">Se connecter</a>
          
              </span>
          
            </span>  
           </div>
  
          </div>        
  
      
    
        <div class="row no-gutters h-100">
          <div class="auth-form__meta d-flex mt-4">
             
  
            <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
          </div> 
          <div class="col-xl-5 col-lg-7 col-md-7 auth-form mx-auto">
            <div class="auth-form__meta d-flex mt-4">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
            </div> 
            <div class="card">
              <div class="card-body">
                  <h5 class="auth-form__title text-center mb-4">Création de compte</h5>
                  <h4 class="auth-form__title text-center mb-4 text-danger" *ngIf="doNotMatch">Le mot de passe et sa confirmation ne sont pas identique</h4>
                  <h4 class="auth-form__title text-center mb-4 text-success" *ngIf="success">Mot de passe changé avec succès. <br> <a routerLink="/login"> Se connecter</a></h4>
                  <h4 class="auth-form__title text-center mb-4 text-danger" *ngIf="error && !errorMessage">Erreur réessayer </h4>
                  <h4 class="auth-form__title text-center mb-4 text-danger" *ngIf="errorMessage && error">{{errorMessage}} </h4>
  
  
                  
               
                <form (ngSubmit)="createUser()"  [formGroup]="phoneForm" #f="ngForm">
                  <div class="form-group">
                  
                     <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder ="Nom" [(ngModel)]="user.firstName">
                    </div>
                    <div class="form-group">
                  
                      <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder ="Prénom" [(ngModel)]="user.lastName">
                     </div>
                  <div class="form-group">
                    <span>
                      <ngx-intl-tel-input  class="form-control" [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.France" id="phonex" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                      [phoneValidation]="true" [separateDialCode]="separateDialCode" 
                      [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone"    >
                    </ngx-intl-tel-input >
                  </span>
                      </div>
       
                  <div class="form-group">
                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="user.email"  [ngModelOptions]="{standalone: true}"  placeholder="Adresse mail">
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control" id="exampleInputPassword1" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.password" placeholder="Mot de passe">
                  </div>    
                 
                  <label class="password-indic" >Le mot de passe doit contenir au minimum 6 caractères,
                    des lettres minuscules, au moins 1 lettre majuscule, 1 chiffre et 1 caractère spécial</label>            

                  <button type="submit"    [disabled]="!user.lastName  || !f.form.valid  || !user.email || !user.password "
                  class="btn btn-pill btn-accent d-table mx-auto" >Suivant</button>
                </form>
              </div>
              <div class="card-footer border-top">
              </div>
            </div>
            <div class="auth-form__meta d-flex">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
      <div class="retour">
  
              <p > <a href="https://www.kairos-opportunities.com">Retour</a> </p>

      </div>
  
  
            </div> 
            
          </div>
          
        </div>
        <div class="copyright">
  
          <span  class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
            <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a href="https://www.kairos-opportunities.com">Mentions legales</a>
            <a href="https://www.kairos-opportunities.com">CGVU</a>
            <a href="https://www.kairos-opportunities.com">Confidentialité</a>
            <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>

            <a routerLink="/dashboard" fragment="nousContacter">Contact</a>
      
      
          </span>  
        </div> 
      </div>
    
      <div id="snackbar">Sauvegarde effectuée avec succès</div>
      <div id="snackbar2">Le mot de passe doit contenir au minimum 6 caractères,
         des lettres minuscules, au moins 1 lettre majuscule, 1 chiffre et 1 caractère spécial
      </div>
    </main>
     <ngx-loading [show]="loading" [config]="{primaryColour: primaryColour, secondaryColour: secondaryColour, tertiaryColour: primaryColour, backdropBorderRadius: '3px'}"
        [template]="customLoadingTemplate"></ngx-loading> 
  </div>
  





  