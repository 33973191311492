import { Component, OnInit,ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user-service.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  resetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
   
  });
  resetpass: boolean;
  resetfail: boolean;

  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(private fb: FormBuilder, private usrSer:UserService,) { }

  ngOnInit(): void {
  }

  reset(){
    this.resetpass = false;
    this.resetfail = false;

    this.loading = true;
    this.usrSer.resetPass(this.resetForm.get('email').value).subscribe((data)=>{
      console.log(data);
      this.resetpass = true;
      this.loading = false;
    },()=>{
      this.resetfail = true;
      this.loading = false;
    })
  }
}
