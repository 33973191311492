import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
@Component({
  selector: 'app-accountcreate',
  templateUrl: './accountcreate.component.html',
  styleUrls: ['./accountcreate.component.scss']
})
export class AccountcreateComponent implements OnInit {
  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
    
      
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
    
  userName: any;
  ID: any;
  userData: any;
  id: any;
  civilite:any="Monsieur";
  prenom:any; 
  nom:any; 
  Datedenaissance:any; 
  activite:any;
  password:any="";
  password2:any="";
  factures:any;
  activites:any={};
  user: any={};
  pack:any;
  period:any;
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.Switzerland];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required]) 
	});

  error=false;
  doNotMatch=false;
  success=false;
  key: any;
  initialized: boolean;
  errorMessage: any;
	changePreferredCountries() {
		this.preferredCountries = [CountryISO.France, CountryISO.Canada];
	}
  constructor(private route: ActivatedRoute ,private usrService:UserService, private router:Router,
      private storage: LocalStorageService, private confirmationDialogService: ConfirmationDialogService,) {
        this.route.queryParams.subscribe(params => {
           this.pack = params['pack'];
           this.period = params['period'];
          console.log(this.period);  
      });
  
       }

  ngOnInit(): void {
    this.user.civilite="Monsieur";
    this.user.profile="User"
    

  }



    
  public openConfirmationDialogDel(title: string, message: string,id,i): void {
    this.confirmationDialogService
      .confirm(title, message)
      .then(confirmed => {
        console.log('User confirmed:', confirmed);
        if (confirmed) 
        console.log("confirmed"); 
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }


  createUser(){
    console.log(this.phoneForm.get("phone").valid)
    
    this.user.phone=this.phoneForm.get("phone").value.internationalNumber.replace(/\s+/g, '');
    if(!this.validatePassword(this.user.password)) return;
    this.loading = true
    this.error = false;
    this.usrService.createUser(this.user).subscribe((data)=>{
      console.log(data)
      this.loading = false
      this.showtoast()
      this.storage.clear('login');
      this.storage.store('login', this.user);
      this.router.navigate(['/subscribe'],{queryParams: {pack: this.pack,period:this.period, userId: data.content.id}});
      
    },error=>{
      this.loading = false
      console.log(error.error)
      this.showtoast2(error.error.message);
      this.error = true;
      this.errorMessage=error.error.message

    })  
    
  }
  validatePassword(password){
    let passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if(password.match(passw)) 
        { 

          return true;
        }
        else{
          this.showtoast2("Le mot de passe doit contenir au minimum 6 caractères,des lettres minuscules, au moins 1 lettre majuscule, 1 chiffre et 1 caractère spécial");

          return false;
        }
  }

  
  showtoast2(message?){
 // Get the snackbar DIV
 var x = document.getElementById("snackbar2");
 if(message){
  x.innerHTML = message;
 }
  
 // Add the "show" class to DIV
 x.className = "show";

 // After 3 seconds, remove the show class from DIV
 setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
  showtoast() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
  
    // Add the "show" class to DIV
    x.className = "show";
  
    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  
}
