
<div class="row h-100">
  <div class="main-navbar sticky-top ">
    <!-- Main Navbar -->

    <div class="pos-f-tb menu-bar">
      <nav class="navbar navbar-dark menu-button ">
                <a class="lienTop" href="#">
          <div class="contentlienTop">
            <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
          </div>
        </a>
        <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <i   class="fas fa-times" ></i>  
          <i  class="fas fa-bars" ></i>
 
        </button>
      </nav>
      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-white p-4 border-black">
          <ul class="nav nav--no-borders flex-column">             
            
            <li class="nav-item2 "  >
              <span><a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
              </span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " > 
              <span><a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a></span><span class="symbol">&#10095;</span>
            </li>
   
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a routerLink="/login"  class="selected">Se connecter</a></span><span class="symbol">&#10095;</span>
            </li> 
          </ul>      
      </div>
    </div>
  </div>
</div>
    <main class="main-content col">
        <div class="main-content-container container-fluid px-4 my-auto h-100">
          <div class="top-element row">
          
            <span  class="kairos_name_logo col-md-4 col-sm-12 col-xs-12"> 
              <img class="logo_top" src="../../assets/images/shards-dashboards-logo.svg"
               alt="Shards Dashboards - Register Template">
              <span routerLink="/#" class="kairos_name">KAIROS</span>
            </span> 
    
                
      
            </div>        
    
        
      
          <div class="row no-gutters h-100">
            <div class="auth-form__meta d-flex mt-4">
               
    
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
            </div> 
            <div class="col-xl-5 col-lg-7 col-11 col-md-7 auth-form mx-auto">
              <div class="auth-form__meta d-flex mt-4">
                <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
              </div> 
              <div class="card">
                <div class="card-body">
                  
                    <h5 class="auth-form__title text-center mb-4">Vous allez recevoir un code par sms. Veuillez le saisir ici</h5>

    
                 
                  <h4 *ngIf="generate" class="text-success">Regarder dans vos SMS le code envoyé</h4> 
                  <h4 *ngIf="resetfail" class="text-danger">erreur réessayer</h4>
                  <form (ngSubmit)="reset()" [formGroup]="resetForm">
                                        <div class="form-group">
    <!--                   <label for="exampleInputEmail1">Adresse email</label>
      
     -->                  
     <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entrer votre code" formControlName="code">
     <small id="emailHelp" class="form-text text-muted text-center"></small>

                    </div>
                    
                  
                    <button type="submit" class="btn btn-pill btn-accent d-table mx-auto" [disabled]="resetForm.invalid">Valider</button>
                  </form>
                </div>
                <div class="card-footer border-top">
                </div>
              </div>
              <div class="auth-form__meta d-flex">
                <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
        <div class="retour">
    
                <p (click)="regenerate()">Regénérer le code</p>

        </div>
    
    
              </div> 
              
            </div>
            
          </div>
             </div>
      
    
      </main>
       <ngx-loading [show]="loading" [config]="{primaryColour: primaryColour, secondaryColour: secondaryColour, tertiaryColour: primaryColour, backdropBorderRadius: '3px'}"
          [template]="customLoadingTemplate"></ngx-loading> 
    </div>
    