<div class="container-fluid">
    <div class="row">
      <div class="main-navbar sticky-top ">
        <!-- Main Navbar -->
    
        <div class="pos-f-tb menu-bar">
          <nav class="navbar navbar-dark menu-button ">
            <a class="lienTop" href="#">
              <div class="contentlienTop">
                <img id="main-logo" class="imageTop" style="max-width: 25px;" src="../../assets/images/shards-dashboards-logo.svg" alt="Shards Dashboard">
                <span class="kairosTop">KAIROS</span>
              </div>
            </a>
            <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <i   class="fas fa-times" ></i>  
              <i  class="fas fa-bars" ></i>
     
            </button>
          </nav>
          <div class="collapse" id="navbarToggleExternalContent">
            <div class="bg-white p-4 border-black">
              <ul class="nav nav--no-borders flex-column">             
                
                <li class="nav-item2 "  >
                  <span><a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
                  </span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " > 
                  <span><a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a></span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " >
                  <span><a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a></span><span class="symbol">&#10095;</span>
                </li>
       
                <li class="nav-item2 " >
                  <span><a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a></span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " >
                  <span><a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a></span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " >
                  <span><a routerLink="/login"  class="selected">Se connecter</a></span><span class="symbol">&#10095;</span>
                </li> 
              </ul>      
          </div>
        </div>
      </div>
    </div>
<main class="main-content col-lg-12 col-md-12 col-sm-12 p-0">
<div class="main-content-container container-fluid px-4 mb-4">


    <!-- Page Header -->
    <div class="top-element row">
      
      <div id="logo-container-mobile" class="col-lg-3 col-md-3 logo-container middle">
        <div id="main-logo" class="navbar-header style-light">
          <a href="https://www.kairos-opportunities.com/" class="navbar-brand" data-minheight="20"><div class="logo-image main-logo logo-skinnable" data-maxheight="63" style="height: 63px;"><img src="../../assets/images/kairos.png
" alt="logo" width="645" height="188" class="img-responsive"></div></a>
        </div>
        <div class="mmb-container"><div class="mobile-additional-icons"></div><div class="mobile-menu-button 1 mobile-menu-button-light lines-button x2"><span class="lines"></span></div></div>
      </div>
     
 <div class=" kairos_menu col-lg-9 col-md-9 col-sm-12 col-xs-12">
  <span  >
    <a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
    <a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a>
    <a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a>
    <a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a>
    <span  class="kairos_login">
      <a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a>
      <a routerLink="/login"  class="selected">Se connecter</a>

    </span>

  </span>  
 </div>
      

      </div>  
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-12 text-center search-data">
        <div class="intern">
          
        <h3 class="page-title2 ">Résultat de votre recherche</h3>
        <span class=" criteria" *ngIf="link">{{link}}</span> 
        

         
      </div>  
      </div>
    </div>
    <div class="intern2 text-center">
          
      <a href="https://www.kairos-opportunities.com" class="return">Nouvelle recherche</a>
      
    </div>  
    <div class="row stats">
      <span  *ngIf="data" 
      class="opport col col-6 col-md-3 col-lg-2 col-sm-12 ">Nombre d’opportunités</span>  

      <div class="opport2 col col-6 col-md-8 col-sm-12 col-lg-2">
        <span class="results">{{data.content}}</span>    


      </div>
      
      <div class="col  col-6  col-6 col-sm-5 buttons">
        <div id="transaction-history-date-range" class="input-daterange input-group input-group-sm ml-auto">
       
        </div>
      </div>
    </div>
    
    <!-- End Page Header -->
    <!-- Transaction History Table -->
     <table class="transaction-history display" id="example">
      <thead>
        <tr>
          <th>Date <i class="fa fa-sort-desc" aria-hidden="true"></i></th>
          <th>Nom<i class="fa fa-sort-desc" aria-hidden="true"></i></th>
          <th>Code APE<i class="fa fa-sort-desc" ></i></th>
          <th>Activités<i class="fa fa-sort-desc" aria-hidden="true"></i></th>
          <th class="big-col">Nature du jugement<i class="fa fa-sort-desc" ></i></th>
         
        </tr>
   
      </thead>
      <tbody >
      

      </tbody> 

     
    </table> 
    <div class="tabback" > 
      <div class="tabfront">
      <div class="discover"> Pour découvrir le résultat veuillez vous abonner</div>
      <a  href="https://www.kairos-opportunities.com/nos-tarifs/">      
         <button  type="button"  class="btn btn-primary consulter2"  >Passer premium</button>
      </a>
      </div> 
   </div>
    <!-- End Transaction History Table -->
  </div>
  <div id="snackbar2">Vous devez souscrire à un abonnement illimité pour bénéficier de cette fonctionnalité </div>

</main>
    </div>
</div>