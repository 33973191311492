import { Component, OnInit, Inject } from '@angular/core';
import { EntrepriseService } from 'src/app/services/entreprise.service';
import { ActivatedRoute, Router } from '@angular/router';


declare const $: any;


@Component({
  selector: 'app-publicsearch',
  templateUrl: './publicsearch.component.html',
  styleUrls: ['./publicsearch.component.scss']
})
export class PublicsearchComponent implements OnInit {

  entreprises:any;
  down = true;

  

  code:boolean;

  table: any;
  saveButton: boolean=false;
  searchResult:[]=[];
  

  
  key: any;
  criteria:any;
  link: string="";
  data:any;
  constructor(private entService:EntrepriseService,private route: ActivatedRoute, 
    private router:Router ) { 
      this.route.queryParams.subscribe(params => {
        this.criteria = params['search'];
       console.log(this.criteria);  
   });
    }

  ngOnInit(): void {

      this.code = false;
      console.log( this.criteria);

      
      this.key =  this.criteria ||'';
      this.link=this.key
     

      

      this.entService.publicSearch( this.key).subscribe((data) =>{
         this.down = false;
             console.log(data);
             this.data=data
             //this.entreprises = data.content;
               
             
          
       }, (()=>{
         this.down = false;
       })
        )
    

   



}



}