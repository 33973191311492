import { Component, OnInit,ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user-service.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';
import { Router } from '@angular/router';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class OtpPassComponent implements OnInit {

  resetForm = this.fb.group({
    code: ['', [Validators.required]],
   
  });
  resetpass: boolean;
  resetfail: boolean;

  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
  generate=false;

  constructor(private fb: FormBuilder, private usrSer:UserService,
    private router: Router,) { }

  ngOnInit(): void {
  }

  reset():void{
    this.generate = false;
    this.loading = true;
    this.resetfail = false;
    this.usrSer.checkOtp(this.resetForm.get('code').value).subscribe((data)=>{
      console.log(data);
     /*  this.resetpass = true; */
      this.loading = false;
      this.router.navigate(['/dashboard']);
    },()=>{
      this.resetfail = true;
      this.loading = false;
    })
  }

  regenerate():void{
    this.resetfail = false;
    this.loading = true;
    this.generate = false;
    this.usrSer.RegenerateCode().subscribe(()=>{
      this.loading = false;
      this.generate = true;
       console.log('sucess.....');
    },()=>{
      this.resetfail = true;
      this.loading = false;
    })
  }
}
