import { Component, OnInit, TemplateRef } from '@angular/core';
import { EntrepriseService } from 'src/app/services/entreprise.service';
import { ActivatedRoute,Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import {Location} from '@angular/common';
import { UserService } from 'src/app/services/user-service.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';
import { loadStripe } from '@stripe/stripe-js';
import { ViewChild } from '@angular/core';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
@Component({
  selector: 'app-change-ape',
  templateUrl: './change-ape.component.html',
  styleUrls: ['./change-ape.component.scss']
})
export class ChangeApeComponent implements OnInit {
  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
    
      
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
    
  userName: any;
  ID: any;
  id: any;
  civilite:any="Monsieur";
  prenom:any; 
  nom:any; 
  Datedenaissance:any; 
  activite:any;
  password:any="";
  password2:any="";
  factures:any;
  activites:any={content:[]};
  user: any={"content":{}};
  stripePromise: any = null;
  stripe: any = null;
  subscribe_success: boolean;
  success: boolean;
  login: any;
  verified:boolean=false;
  terminated:boolean=false;
  session_id:String;
  emptyPhoto="../../assets/images/avatars/user.jpg"

  constructor(private route: ActivatedRoute ,private usrService:UserService,
     private router:Router,private entService:EntrepriseService,
      private storage: LocalStorageService) {
        this.user.content.profileUrl=this.emptyPhoto;
        this.route.queryParams.subscribe(params => {
     

          this.stripePromise = loadStripe('pk_test_51HvfjMIIm1dUuideT1WXqIVCQR3BVroCBegfEXvhdiXZGIAAoGYddJx6DOzCPZDnXYkgjalqbVJRIVayY98iQnB200VDL1JxPG')
          .then(res => {
            this.stripe = res;
            console.log(res)
          });
      });
  
       }

  ngOnInit(): void {
    this.usrService.identity().subscribe((data)=>{
      console.log(data);
      this.user = data;
        
  if(!this.user.content.profileUrl){
    this.user.content.profileUrl=this.emptyPhoto

    
   }else{
    this.user.content.profileUrl=this.usrService.profilePictureUrl+"/"+ this.user.content.profileUrl
   }
   if(!this.user.content.companyUrl){
    this.user.content.companyUrl=this.emptyPhoto
   }else{
    this.user.content.companyUrl=this.usrService.profilePictureUrl+"/"+ this.user.content.companyUrl

   }
    })  
    

    //dffdshfghfdhdfhgh
    this.subscribe_success=true;
    this.route.queryParams.subscribe(params => {
      let status = params['status'];
      this.session_id= params['session_id'];

      console.log(status)
      console.log( this.session_id)
      if(status=="SUCCESS"){
        this.success=true;
        //this.updatePayment(this.user.content.id,session_id,status)
      }
      if(status=="CANCEL"){
        this.success=false;
        this.showtoast2("Votre opération a été annulée!")
      }
      
     
    })
    ///aezrzerzerzerez
    this.entService.getActivites2().subscribe((data)=>{
      this.activites.content=data.content;

      console.log(this.activites);
    })  
  }
  updatePayment(userId: any, session_id: any,status:any) {
    this.loading = true
    console.log("+++++++++++++++++++++++++++=")

    this.usrService.updatePayment(userId,session_id,status).subscribe((data)=>{
      console.log(data)
      this.loading = false  
      if( this.success){
        
        this.showtoast()
        this.loading = false
        this.verified=true

      }
     
    },error=>{
      this.loading = false
      console.log(error.error)
      this.showtoast2(error.error.message);

    })  
  }


  clearAndSaveToken(token){
    this.usrService.tokens=token;
    this.storage.clear('token');
    this.storage.store('token', token);
  }

  changeApe() {
    this.usrService.changeApe(this.activite,this.session_id).subscribe((data)=>{
      this.terminated=true;
      this.showtoast("Votre code APE a été changé avec succès!");

      console.log(data)
    },error=>{
      
      this.showtoast2(error.error.message);
      this.terminated=false;

      console.log(error.error)
    })  




  }

  getActivity(activity){
    this.activite=activity
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("dropbtn").innerHTML=activity 
    

  }
   myFunction() { 
    document.getElementById("myDropdown").classList.toggle("show");
  }
  
   filterFunction() { 
    var input, filter, ul, li, a, i;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    let div = document.getElementById("myDropdown");
    a = div.getElementsByTagName("a");
    for (i = 0; i < a.length; i++) {
      let txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  }
  showtoast2(message?){
 // Get the snackbar DIV
 var x = document.getElementById("snackbar2");
 if(message){
  x.innerHTML = message;
 }
  
 // Add the "show" class to DIV
 x.className = "show";

 // After 3 seconds, remove the show class from DIV
 setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
  showtoast(message?) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

 if(message){
  x.innerHTML = message;
 }
  
    // Add the "show" class to DIV
    x.className = "show";
  
    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
  }

  
  logout(){
    this.usrService.tokens=null;
    this.storage.clear();
    this.router.navigate(["/login"]);
  }

}
