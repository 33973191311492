import { Component, OnInit } from '@angular/core';
import { EntrepriseService } from 'src/app/services/entreprise.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-publicanalytics',
  templateUrl: './publicanalytics.component.html',
  styleUrls: ['./publicanalytics.component.scss']
})
export class PublicanalyticsComponent implements OnInit {

  entreprises:any;
  down = true;
  entTotal:any=0;
  weekTotal:any=0;
  year:any=0;
  code:boolean;
  data:any={content:{}};
  table: any;
  saveButton: boolean=false;
  searchResult:[]=[];
  weekNumber=0;
  key: any;
  link: string="";
  constructor(private entService:EntrepriseService,private route: ActivatedRoute, 
    private router:Router ) { 
      this.route.queryParams.subscribe(params => {
        this.key = params['week'];
       console.log(this.key);  
   });
    }

  ngOnInit(): void {

      this.code = false;

      this.entService.publicAnalytics(this.key).subscribe((data) =>{
         this.down = false;
             console.log(data);
             this.data=data
             this.weekNumber = data.content.week;

       }, (()=>{
         this.down = false;
       })
        )

}

}