<div class="row h-100">
  <div class="main-navbar sticky-top ">
    <!-- Main Navbar -->

    <div class="pos-f-tb menu-bar">
      <nav class="navbar navbar-dark menu-button ">
        <a class="lienTop" href="#">
          <div class="contentlienTop">
            <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
          </div>
        </a>
        <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <i   class="fas fa-times" ></i>  
          <i  class="fas fa-bars" ></i>
 
        </button>
      </nav>
      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-white p-4 border-black">
          <ul class="nav nav--no-borders flex-column">             
            
            <li class="nav-item2 "  >
              <span><a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
              </span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " > 
              <span><a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a></span><span class="symbol">&#10095;</span>
            </li>
   
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a routerLink="/login"  class="selected">Se connecter</a></span><span class="symbol">&#10095;</span>
            </li> 
          </ul>      
      </div>
    </div>
  </div>
</div>
  <main class="main-content col">
      <div class="main-content-container container-fluid px-4 my-auto h-100">
        <div class="top-element row">
        
          <div id="logo-container-mobile" class="col-lg-3 col-md-3 logo-container middle">
            <div id="main-logo" class="navbar-header style-light">
              <a href="https://www.kairos-opportunities.com/" class="navbar-brand" data-minheight="20">
                <div class="logo-image main-logo logo-skinnable" data-maxheight="63" style="height: 63px;">
                  <img src="../../assets/images/kairos.png
" alt="logo" width="645" height="188" class="img-responsive"></div></a>
            </div>
            <div class="mmb-container">
              <div class="mobile-additional-icons"></div>
              <div class="mobile-menu-button 1 mobile-menu-button-light lines-button x2">
                <span class="lines"></span></div></div>
          </div> 
         
          <div class=" kairos_menu col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <span  >
              <a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
              <a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a>
            <!--    <a href="#">Partenaires</a> -->
              <a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a>
              <a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a>
              <span  class="kairos_login">
                <a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a>
                <a routerLink="/login"  class="selected">Se connecter</a>
          
              </span>
          
            </span>  
           </div>
    
          </div>        
  
      
    
        <div class="row no-gutters h-100">
          <div class="auth-form__meta d-flex mt-4">
             
  
            <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
          </div> 
          <div class="col-xl-5 col-lg-7 col-11 col-md-7 auth-form mx-auto">
            <div class="auth-form__meta d-flex mt-4">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
            </div> 
            <div class="card">
              <div class="card-body" *ngIf="subscribe_success&&success">
                  <h5 class="auth-form__title text-center mb-4">Abonnement</h5>
  
               
                     <form class="myform" >
                      <div class="row row-form">
                      
                      <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                        <div class="entreprises text-center">paiement</div>
                        <div class="entreprises text-center">Votre paiement a été effectué avec succès</div>
                        <div class="entreprises text-center">Vous serez redirigé sur la page d'accueil de l'application dans un instant</div>
                        <div class="entreprises text-center"><a routerLink="/login">login</a></div>

                        
                      </div> 
                      
                    </div>
                  </form>
                  
                
   
    <!-- <div class="text-center">    
      <button  type="button" class="btn btn-primary consulter2" routerLink="/login" >se connecter</button>
    </div>-->
              </div>

              <div class="card-body" *ngIf="!subscribe_success">
                <h5 class="auth-form__title text-center mb-4">Abonnement</h5>

             
                   <form class="myform" >
                    <div class="row row-form" *ngIf="pack==1">
                    
                    <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                      <div class="entreprises"></div>
                      <label class="password-indic" >Votre formule vous donne droit à l'utilisation d'un code APE UNIQUE. Veuillez le sélectionner</label> 
                    <!--   <input list="activite" class="form-control"  [(ngModel)]="activite" [ngModelOptions]="{standalone: true}" placeholder ="Activité">
                      <datalist id="activite">
                        <option   *ngFor="let data of activites.content;let i = index" value={{data.code}}>{{data.code}} - {{data.libelle}}</option>

                      </datalist>   -->
                      <div class="dropdown"   >   
                        <button (click)="myFunction()" class="dropbtn" id="dropbtn">Activité</button>
                        <div id="myDropdown2" class="dropdown-search">
                          <input type="text" placeholder="recherche.." id="myInput"  [(ngModel)]="activite"  [ngModelOptions]="{standalone: true}" (keyup)="filterFunction()">

                        </div>
                        <div id="myDropdown" class="dropdown-content">
                          <a *ngFor="let data of activites.content;let i = index" (click)="getActivity(data.code)" >{{data.code}} - {{data.libelle}}</a>
   
                        </div>
                      </div>
                    </div> 
                    
                  </div>
                 
               
                <div class="entreprises text-center" style="margin-top: 10px;">Forfait {{forfait}}, Abonnement {{periodeAbonnement}}
                </div>
                <div class="" style="margin-top: 10px;">
                  <h3 class="pack-details  "  *ngIf="periodValue==1" > <div>Montant HT : </div>  <div class="pricing"> {{plan.annualAmount}} {{plan.devise}} </div></h3>
                  <h3 class="pack-details  "  *ngIf="periodValue!=1" > <div>Montant HT : </div><div class="pricing">{{plan.amount}} {{plan.devise}}</div></h3>
                  <h3 class="pack-details  "  *ngIf="periodValue==1" ><div>TVA : </div> <div class="pricing"> {{plan.annualTva}} {{plan.devise}}</div></h3>
                  <h3 class="pack-details  "  *ngIf="periodValue!=1" ><div>TVA : </div><div class="pricing"> {{plan.tva}} {{plan.devise}}</div></h3>
                  <h3 class="pack-details  "  *ngIf="periodValue==1" ><div>Montant TTC :</div> <div class="pricing"> {{plan.annualTTCAmount}} {{plan.devise}}</div></h3>
                  <h3 class="pack-details  "  *ngIf="periodValue!=1" > <div>Montant TTC :</div><div class="pricing"> {{plan.TTCAmount}} {{plan.devise}}</div></h3>
                  <h3 class="pack-details  "  > Détails :  {{plan.detail}}</h3>

                </div>
                <div class="form-group mb-3 d-table mx-auto">
                  <div class="custom-control custom-checkbox mb-1">
                   <input type="checkbox" class="custom-control-input" id="customCheck2" [ngModelOptions]="{standalone: true}" [(ngModel)]="conditions">
                  <label class="custom-control-label" for="customCheck2" >Je déclare avoir pris connaissance des
                     <b class="bold"> <a href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">conditions générales d’utilisation </a></b>
                     de la plateforme Kairos que j’accepte expressément. </label>            

                 </div> 
               </div> 
              </form>
  <div class="text-center">    
      <button  type="button" [disabled]="pack==1 && !activite || !conditions" class="btn btn-primary consulter2" (click)="createCheckoutSession(periodValue)" >Continuer</button>
    </div> 
 
            </div>
            <div class="card-body" *ngIf="subscribe_success&&!success">
              <h5 class="auth-form__title text-center mb-4">Abonnement</h5>

              
                 <form class="myform" *ngIf="pack==1">
                  <div class="row row-form">
                  
                  <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <div class="entreprises text-center">Votre formule vous donne droit à l'utilisation d'un code APE UNIQUE. Veuillez le sélectionner</div>

                    <!-- <input list="activite" class="form-control"  [(ngModel)]="activite" [ngModelOptions]="{standalone: true}" placeholder ="Activité">
                    <datalist id="activite">
                      <option   *ngFor="let data of activites.content;let i = index" value={{data.code}}>{{data.code}} - {{data.libelle}}</option>

                    </datalist>   -->
                    <div class="dropdown"   >   
                      <button (click)="myFunction()" class="dropbtn" id="dropbtn">Activité</button>
                      <div id="myDropdown" class="dropdown-content">
                        <input type="text" placeholder="recherche.." id="myInput"  [(ngModel)]="activite"  [ngModelOptions]="{standalone: true}" (keyup)="filterFunction()">
                        <a *ngFor="let data of activites.content;let i = index" (click)="getActivity(data.code)" >{{data.code}} - {{data.libelle}}</a>
 
                      </div>
                    </div>
                  </div> 
                  
                </div>
              </form>
              <div class="entreprises text-center">echec du paiement</div>
              <div class="entreprises red text-center">Votre paiement n'a pas été effectué avec succès
              </div>  
              <div class="entreprises text-center" style="margin-top: 10px;">Choisissez le type d'abonnement auquel vous voulez souscrire
              </div>
            

<div class="text-center">    
    <button  type="button" [disabled]="pack==1 && !activite" class="btn btn-primary consulter2" (click)="createCheckoutSession(1)" >Abonnement annuel</button>
  </div> <div class="text-center"> 
    <button  type="button" [disabled]="pack==1 && !activite" class="btn btn-primary consulter2" (click)="createCheckoutSession(0)" >Abonnement mensuel</button>

</div>
          </div>
            
              <div class="card-footer border-top">
              </div>
            </div>
            <div class="auth-form__meta d-flex mt-4">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
      <div class="retour">

  
      </div>
  
  
            </div> 
            
          </div>
        </div>
        <div class="copyright">
          <span  class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
            <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a href="https://www.kairos-opportunities.com/mentions-legales">  Mentions legales</a>
            <a href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">CGVU</a>
            <a href="https://www.kairos-opportunities.com/politique-de-confidentialite">Confidentialité</a>
            <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>

            <a routerLink="/dashboard" fragment="nousContacter">Contact</a>
      
      
          </span>  
        </div>   
      </div>
    
  
    </main>
    <ngx-loading [show]="loading" [config]="{primaryColour: primaryColour, secondaryColour: secondaryColour, tertiaryColour: primaryColour, backdropBorderRadius: '3px'}"
    [template]="customLoadingTemplate"></ngx-loading> 
    <div id="snackbar">Paiement effectué avec succès!</div>
    <div id="snackbar2">Echec du paiement</div>
  </div>
  