<div class="container-fluid">
  <div class="row">
    <!-- Main Sidebar -->
    <div class="main-navbar sticky-top ">
      <!-- Main Navbar -->

      <div class="pos-f-tb menu-bar">
        <nav class="navbar navbar-dark menu-button ">
                 <a class="lienTop" href="#">
          <div class="contentlienTop">
            <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
          </div>
        </a>
          <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
            <i   class="fas fa-times" ></i>  
            <i  class="fas fa-bars" ></i>
   
          </button>
        </nav>
        <div class="collapse" id="navbarToggleExternalContent">
          <div class="bg-white p-4 border-black">
            <h4 class="text-dark text-center"> Bonjour {{userName}} </h4>
            <ul class="nav nav--no-borders flex-column">             
              <li class="nav-item2 selected" routerLink="/dashboard" >  
                <span>Tableau de bord</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " routerLink="/search" >
                <span>Mes recherches</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " routerLink="/entreprise/weekdetails" > 
                <span>Statistiques</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 "  routerLink="/profile">
                <span>Mon compte</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " routerLink="/utilisateur" *ngIf="user.content.profile=='Admin'">  
                <span>Utilisateurs</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " (click)="logout()" >  
                  <span>Déconnexion</span><span class="symbol">&#10095;</span>
              </li>
            </ul>      
        </div>
      </div>
    </div>
  </div>
    <aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
      <div class="main-navbar">
        <nav class="navbar align-items-stretch  flex-md-nowrap p-0">
          <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
            <div class="d-table m-auto">
              <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px;" src="../../assets/images/shards-dashboards-logo.svg" alt="Shards Dashboard">
              <span class="d-none kairos d-md-inline ml-1">KAIROS</span>
            </div>
          </a>
          
        </nav>
      </div>
  
      <div class="nav-wrapper">
        <div class="user">
        
            <div class="avatar-div" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
              <img class="user-avatar mr-2" src="{{user.content.profileUrl}}" alt="User Avatar">
            </div> 
              <div class="userName d-none d-md-inline-block" *ngIf="userName">
                Bonjour {{userName}}</div>
                <div class="welcome">
                Bienvenue sur votre espace
              </div>
              
          </div>
        
        <ul class="nav nav--no-borders flex-column">
          
          <li class="nav-item " routerLink="/dashboard" >  
            <span>Tableau de bord</span>
          
          
        </li>
        <li class="nav-item selected" routerLink="/search" >
          <span>Mes recherches</span>
        
        
      </li>
      <li class="nav-item " routerLink="/entreprise/weekdetails">
        <span>Statistiques</span>
      
      
    </li>
    <li class="nav-item "  routerLink="/profile">
      <span>Mon compte</span>
    
    
  </li>
  <li class="nav-item " routerLink="/utilisateur" *ngIf="user.content.profile=='Admin'">  
    <span>Utilisateurs</span>
  
  
</li>
    <li class="nav-item " (click)="logout()" >  
        <span>Déconnexion</span>
      
      
    </li>

        </ul>
      </div>
    </aside>
    <!-- End Main Sidebar -->
    <main class="main-content col-12  col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
      <div class="main-navbar sticky-top ">
        <!-- Main Navbar -->
   
      </div> <!-- / .main-navbar -->
 
      <div class="main-content-container container-fluid px-4">
        <div class="entreprises text-center">Changement du code APE</div>

        <div class="row no-gutters h-100">
          <div class="auth-form__meta d-flex mt-4">
             
  
            <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
          </div> 
          <div class="col-lg-12 col-md-12 col-12 ">

            <div class="auth-form__meta d-flex">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
            </div> 
            <div class="card">
              <div class="card-body mybody text-center">
               
                <form class="myform" >
                  <div class="row row-form">
                  
                  <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <div class="entreprises green text-center" *ngIf="success">Votre paiement a été effectué avec succès</div>  

                    <div class="entreprises red text-center" *ngIf="!success">Votre paiement n'a pas été effectué avec succès</div>  
                    
                    <div class="entreprises text-center" *ngIf="success && !terminated"  style="margin-top: 10px;">Choisissez Votre nouveau code APE</div>
                   <!--  <input  *ngIf="success && !terminated" list="activite" class="form-control"  [(ngModel)]="activite" [ngModelOptions]="{standalone: true}" placeholder ="Activité">
                    <datalist   id="activite">
                      <option   *ngFor="let data of activites.content;let i = index" value={{data.code}}>{{data.code}} - {{data.libelle}}</option>
      
                    </datalist>   -->
                    <div class="dropdown"   *ngIf="success && !terminated">
                      <button (click)="myFunction()" class="dropbtn" id="dropbtn">Activité</button>
                      <div id="myDropdown" class="dropdown-content">
                        <input type="text" placeholder="recherche.." id="myInput"  [(ngModel)]="activite"  [ngModelOptions]="{standalone: true}" (keyup)="filterFunction()">
                        <a *ngFor="let data of activites.content;let i = index" (click)="getActivity(data.code)" >{{data.code}} - {{data.libelle}}</a>
 
                      </div>   
                    </div>
                  </div> 
                  
                </div> 
                <button    *ngIf="success && !terminated" [disabled]="!success" (click)="changeApe()" type="button" class="btn btn-primary text-center">Valider</button>

              </form>
                   
               
              </div>
              <div class="card-footer border-top">
              </div>
            </div>
            <div class="auth-form__meta d-flex">

            </div> 
            
          </div>
          
        </div>
     
      </div>
      <footer class="main-footer text-center border-top">
        <div class="copyright">

          <span    class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
           <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
           <a href="https://www.kairos-opportunities.com/mentions-legales">Mentions legales</a>
           <a href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">CGVU</a>
           <a href="https://www.kairos-opportunities.com/politique-de-confidentialite">Confidentialité</a>
           <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>

           <a routerLink="/dashboard" fragment="nousContacter">Contact</a>
        
        
         </span>  
        </div></footer>
    </main>
  </div>
  <div id="snackbar">Paiement enregistré avec succès</div>
  <div id="snackbar2">échec de l'opération</div>
  <ngx-loading [show]="loading" [config]="{primaryColour: primaryColour, secondaryColour: secondaryColour, tertiaryColour: primaryColour, backdropBorderRadius: '3px'}"
  [template]="customLoadingTemplate"></ngx-loading> 
</div>