import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { NgxLoadingModule } from '../../projects/ngx-loading/src/public_api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {SharedModule} from './shared/shared.module';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { OtpPassComponent } from './otc/reset-pass.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ResearcheBycriteriaComponent } from './researche-bycriteria/researche-bycriteria.component';
import { ChangeResetComponent } from './change-reset/change-reset.component';
import { AccountcreateComponent } from './accountcreate/accountcreate.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PublicsearchComponent } from './publicsearch/publicsearch.component';
import { ChangeApeComponent } from './change-ape/change-ape.component';
import { NgxCropperJsModule } from 'ngx-cropperjs-wrapper';
import { PublicanalyticsComponent } from './publicanalytics/publicanalytics.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProfileComponent,
    DashboardComponent,
    PagenotfoundComponent,
    ResetPassComponent,
    OtpPassComponent,
    ChangePassComponent,
    ResearcheBycriteriaComponent,
    ChangeResetComponent,
    AccountcreateComponent,
    SubscriptionComponent,
    PublicsearchComponent,
    PublicanalyticsComponent,
    ChangeApeComponent
    
  ],
  imports: [
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    NgxIntlTelInputModule,
    
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxCropperJsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ], 
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
