import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserAccessAdminService } from './services/user-access-admin-service';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { OtpPassComponent } from './otc/reset-pass.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ResearcheBycriteriaComponent } from './researche-bycriteria/researche-bycriteria.component';
import { ChangeResetComponent } from './change-reset/change-reset.component';
import { AccountcreateComponent } from './accountcreate/accountcreate.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PublicsearchComponent } from './publicsearch/publicsearch.component';
import { ChangeApeComponent } from './change-ape/change-ape.component';
import { PublicanalyticsComponent } from './publicanalytics/publicanalytics.component';


const routes: Routes = [ 
  {
  path: 'utilisateur',
  loadChildren: () => import('./utilisateurs/utilisateur/utilisateur.module')
  .then(m => m.UtilisateurModule),
  //canActivate: [UserAccessAdminService]
},
{
  path: 'entreprise',
  loadChildren: () => import('./entreprises/entreprise.module')
  .then(m => m.EntrepriseModule),
  //canActivate: [UserAccessAdminService]
},
{
  path: 'login',
  component: LoginComponent
},
{
  path: 'accountcreate',
  component: AccountcreateComponent
},
{
  path: 'subscribe',
  component: SubscriptionComponent
},
{
  path: 'subscribe_success',
  component: SubscriptionComponent
},
{
  path: 'profile',
  component: ProfileComponent,
  canActivate: [UserAccessAdminService]
},
{
  path: 'reset',
  component: ResetPassComponent,
 // canActivate: [UserAccessAdminService]
},
{
  path: 'code',
  component: OtpPassComponent,
 // canActivate: [UserAccessAdminService]
},
{
  path: 'result',
  component: PublicsearchComponent,
},
{
  path: 'analyse',
  component: PublicanalyticsComponent,
},

{
  path: 'change',
  component: ChangePassComponent,
 // canActivate: [UserAccessAdminService]
},
{
  path: 'change-pass',
  component: ChangeResetComponent,
 // canActivate: [UserAccessAdminService]
},
{
  path: 'change-ape',
  component: ChangeApeComponent,
  canActivate: [UserAccessAdminService]
},
{
  path: 'search',
  component: ResearcheBycriteriaComponent,
  canActivate: [UserAccessAdminService]
},{
  path: 'searchUpdate',
  component: ResearcheBycriteriaComponent,
  canActivate: [UserAccessAdminService]
},
{
  path: 'dashboard',
  component: DashboardComponent,
  canActivate: [UserAccessAdminService]
},
{path: '', redirectTo: '/dashboard', pathMatch: 'full'},
{path: '**', component: PagenotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
