import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';
import { loadStripe } from '@stripe/stripe-js';
import { EntrepriseService } from '../services/entreprise.service';


const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
    
      
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
    
  userName: any;
  ID: any;
  userData: any;
  id: any;
  civilite:any="Monsieur";
  prenom:any; 
  nom:any; 
  Datedenaissance:any; 
  activite:any;
  password:any="";
  password2:any="";
  factures:any;
  activites:any={content:[]};
  user: any={};
  stripePromise: any = null;
  stripe: any = null;
  subscribe_success: boolean;
  pack:any;
  userId:any;
  success: boolean;
  login: any;
  conditions:boolean;
  period: any;
  forfait:any;
  periodeAbonnement: string="Mensuel";
  periodValue: number=0;
  plan: any;
  constructor(private route: ActivatedRoute ,private usrService:UserService,
     private router:Router,private entService:EntrepriseService,
      private storage: LocalStorageService, private confirmationDialogService: ConfirmationDialogService,) {
        this.route.queryParams.subscribe(params => {
          this.pack = params['pack'];
          if(this.pack==1){
            this.forfait="KAIROS"
          }
          if(this.pack==2){
            this.forfait="KAIROS+"
          }
          if(this.pack==3){
            this.forfait="KAIROS PREMIUM"
          }
          this.getPlans(this.pack)
          this.userId = params['userId'];
          this.period = params['period'];
          if(this.period=="annual"){
            this.periodeAbonnement="Annuel"
            this.periodValue=1;

          }
          console.log(this.pack,this.userId)

          //this.stripePromise = loadStripe('pk_test_51HvfjMIIm1dUuideT1WXqIVCQR3BVroCBegfEXvhdiXZGIAAoGYddJx6DOzCPZDnXYkgjalqbVJRIVayY98iQnB200VDL1JxPG')
          this.stripePromise = loadStripe('pk_live_51HvfjMIIm1dUuideEDoTISJkjAN2epi32YObqMTrSoCy1IqdfZsH3jUv2HvH2aEIeJwRqbU47jbRJMDPRbpbLjBt00jF3MPM9h')
          .then(res => {
            this.stripe = res;
            console.log(res)
          });
      });
  
       }

  ngOnInit(): void {
    this.user.civilite="Monsieur";
    this.user.profile="User"
    
    if (this.router.url.includes('/subscribe_success') ){
      this.subscribe_success=true;
      this.route.queryParams.subscribe(params => {
        let status = params['status'];
        let session_id = params['session_id'];

        console.log(status)
        console.log(session_id)
        if(status=="SUCCESS"){
          this.success=true;
          this.updatePayment(this.userId,session_id,status)
          this.showtoast();
        }
       else{
          this.success=false;
          this.updatePayment(this.userId,session_id,status)
        }
        //let ref=params['reference'];
        //console.log(ref);  
    });
    }
    this.entService.getActivites2().subscribe((data)=>{
      this.activites.content=data.content;
     /*  for (let index = 0; index < data.content.length; index=index+100) {
        this.activites.content.push( data.content[index]) ;
    
      } */
      //this.activites = data;
      console.log(this.activites);
    })  
  }
  getPlans(idPack){
    this.usrService.getPlans(idPack).subscribe((data:any)=>{
      console.log(data)
      this.plan=data.content
      this.forfait=this.plan.libelle
      this.plan.annualTTCAmount=this.plan.annualAmount+0+this.plan.annualTva
      this.plan.TTCAmount=this.plan.amount+0+this.plan.tva 

      
     
    })}
  updatePayment(userId: any, session_id: any,status:any) {
    this.loading = true

    this.usrService.updatePayment(userId,session_id,status).subscribe((data)=>{
      console.log(data)
      this.loading = false
      if( this.success){
        
        this.showtoast("Paiement effectué avec succès! Vous serez redirigé vers votre tableau de bord dans un instant")
        this.loading = true
        this.login  = this.storage.retrieve('login');
        console.log(this.login)


        
   this.usrService.login({email: this.login.email,
                      password: this.login.password}).subscribe((data:any)=>{
                        this.loading = false
                     console.log(data.content.jwttoken)
                     this.clearAndSaveToken(data.content.jwttoken);
                     let rout=this.router
                     setTimeout(function(){  rout.navigate(['/dashboard']); }, 3000);

                    
                      },
                      (err)=>{
                        console.log(err)
                      

                        this.loading = false;
                        this.showtoast2(err.error.message);
                      }) 
      }
     
    },error=>{
      this.loading = false
      console.log(error.error)
      this.showtoast2(error.error.message);

    })  
  }
  getActivity(activity){
    this.activite=activity
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myDropdown2").classList.toggle("show");

    document.getElementById("dropbtn").innerHTML=activity 
    document.getElementById("dropbtn").classList.toggle("shown");

    

  }
   myFunction() { 
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myDropdown2").classList.toggle("show");  

    document.getElementById("dropbtn").classList.toggle("shown");
    
  }
  
   filterFunction() { 
    var input, filter, ul, li, a, i;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    let div = document.getElementById("myDropdown");
    a = div.getElementsByTagName("a");
    for (i = 0; i < a.length; i++) {
      let txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  }

  clearAndSaveToken(token){
    this.usrService.tokens=token;
    this.storage.clear('token');
    this.storage.store('token', token);
  }
    
  public openConfirmationDialogDel(title: string, message: string,id,i): void {
    this.confirmationDialogService
      .confirm(title, message)
      .then(confirmed => {
        console.log('User confirmed:', confirmed);
        if (confirmed) 
        console.log("confirmed"); 
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  createCheckoutSession(annual) {
    console.log(this.activite)
    if(this.pack==1){
      this.addApe(this.userId,this.activite)
    }
     this.usrService.getSession( this.pack, this.userId,annual).subscribe((data:any)=>{
      console.log(data)
      this.stripe.redirectToCheckout({sessionId: data.content}
        )
  .then(res => {
     console.log(res);
  })
  .catch(error => {
     console.log(error);
  });
    }) 
  };

  addApe(userId: any, codeApe: any) {

    this.usrService.addApe(codeApe,userId).subscribe((data)=>{
      console.log(data)
     
    },error=>{
      console.log(error.error)
      

    })  
  }

  createUser(){
    console.log(this.user.password)
    if(!this.validatePassword(this.user.password)) return;
    this.loading = true
    this.usrService.createUser(this.user).subscribe((data)=>{
      console.log(data)
      this.loading = false
      this.showtoast()
    },error=>{
      this.loading = false
      console.log(error.error)
      this.showtoast2(error.error.message);

    })  
    
  }
  validatePassword(password){
    let passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if(password.match(passw)) 
        { 

          return true;
        }
        else{
          this.showtoast2("Le mot de passe avoir une logueur minimale de 8 caractères et doit contenir une lettre majuscule, un chiffre et un caractère spécial");

          return false;
        }
  }

  
  showtoast2(message?){
 // Get the snackbar DIV
 var x = document.getElementById("snackbar2");
 if(message){
  x.innerHTML = message;
 }
  
 // Add the "show" class to DIV
 x.className = "show";

 // After 3 seconds, remove the show class from DIV
 setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
  showtoast(message?) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    if(message){
      x.innerHTML = message;
     }
    // Add the "show" class to DIV
    x.className = "show";
  
    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 10000);
  }

  
}
