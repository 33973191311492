import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { EntrepriseService } from '../services/entreprise.service';

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {LocalStorageService} from 'ngx-webstorage';
import { UserService } from '../services/user-service.service';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';


const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-researche-bycriteria',
  templateUrl: './researche-bycriteria.component.html',
  styleUrls: ['./researche-bycriteria.component.scss']
})

export class ResearcheBycriteriaComponent implements OnInit {

  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  error = false;
  codeApe: string = '';
  chiffreAffaire: string = '';
  chiffreAffaireMax: string = "";
  confidentialite: any = 'false';
  decision: string;
  decisionMax: string;
  effectif: any;
  effectifMax: any;
  numero:any;
  libelle:any;
  libelleApe:any;
  nature: any = "";
  maxDate: any;
  maxDate2: any;
  minDate2: any;
  maxCa="5000000000";
  departements = [
    {
      "departmentCode" : "01",
      "departmentName" : "Ain",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "02",
      "departmentName" : "Aisne",
      "regionCode" : "32",
      "regionName" : "Nord-Pas-de-Calais-Picardie"
    },
    {
      "departmentCode" : "03",
      "departmentName" : "Allier",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "04",
      "departmentName" : "Alpes-de-Haute-Provence",
      "regionCode" : "93",
      "regionName" : "Provence-Alpes-Côte d'Azur"
    },
    {
      "departmentCode" : "05",
      "departmentName" : "Hautes-Alpes",
      "regionCode" : "93",
      "regionName" : "Provence-Alpes-Côte d'Azur"
    },
    {
      "departmentCode" : "06",
      "departmentName" : "Alpes-Maritimes",
      "regionCode" : "93",
      "regionName" : "Provence-Alpes-Côte d'Azur"
    },
    {
      "departmentCode" : "07",
      "departmentName" : "Ardèche",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "08",
      "departmentName" : "Ardennes",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "09",
      "departmentName" : "Ariège",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "10",
      "departmentName" : "Aube",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "11",
      "departmentName" : "Aude",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "12",
      "departmentName" : "Aveyron",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "13",
      "departmentName" : "Bouches-du-Rhône",
      "regionCode" : "93",
      "regionName" : "Provence-Alpes-Côte d'Azur"
    },
    {
      "departmentCode" : "14",
      "departmentName" : "Calvados",
      "regionCode" : "28",
      "regionName" : "Normandie"
    },
    {
      "departmentCode" : "15",
      "departmentName" : "Cantal",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "16",
      "departmentName" : "Charente",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "17",
      "departmentName" : "Charente-Maritime",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "18",
      "departmentName" : "Cher",
      "regionCode" : "24",
      "regionName" : "Centre-Val de Loire"
    },
    {
      "departmentCode" : "19",
      "departmentName" : "Corrèze",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "21",
      "departmentName" : "Côte-d or",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "22",
      "departmentName" : "Côtes-d armor",
      "regionCode" : "53",
      "regionName" : "Bretagne"
    },
    {
      "departmentCode" : "23",
      "departmentName" : "Creuse",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "24",
      "departmentName" : "Dordogne",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "25",
      "departmentName" : "Doubs",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "26",
      "departmentName" : "Drôme",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "27",
      "departmentName" : "Eure",
      "regionCode" : "28",
      "regionName" : "Normandie"
    },
    {
      "departmentCode" : "28",
      "departmentName" : "Eure-et-Loir",
      "regionCode" : "24",
      "regionName" : "Centre-Val de Loire"
    },
    {
      "departmentCode" : "29",
      "departmentName" : "Finistère",
      "regionCode" : "53",
      "regionName" : "Bretagne"
    },
    {
      "departmentCode" : "2a",
      "departmentName" : "Corse-du-Sud",
      "regionCode" : "94",
      "regionName" : "Corse"
    },
    {
      "departmentCode" : "2b",
      "departmentName" : "Haute-Corse",
      "regionCode" : "94",
      "regionName" : "Corse"
    },
    {
      "departmentCode" : "30",
      "departmentName" : "Gard",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "31",
      "departmentName" : "Haute-Garonne",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "32",
      "departmentName" : "Gers",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "33",
      "departmentName" : "Gironde",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "34",
      "departmentName" : "Hérault",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "35",
      "departmentName" : "Ille-et-Vilaine",
      "regionCode" : "53",
      "regionName" : "Bretagne"
    },
    {
      "departmentCode" : "36",
      "departmentName" : "Indre",
      "regionCode" : "24",
      "regionName" : "Centre-Val de Loire"
    },
    {
      "departmentCode" : "37",
      "departmentName" : "Indre-et-Loire",
      "regionCode" : "24",
      "regionName" : "Centre-Val de Loire"
    },
    {
      "departmentCode" : "38",
      "departmentName" : "Isère",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "39",
      "departmentName" : "Jura",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "40",
      "departmentName" : "Landes",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "41",
      "departmentName" : "Loir-et-Cher",
      "regionCode" : "24",
      "regionName" : "Centre-Val de Loire"
    },
    {
      "departmentCode" : "42",
      "departmentName" : "Loire",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "43",
      "departmentName" : "Haute-Loire",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "44",
      "departmentName" : "Loire-Atlantique",
      "regionCode" : "52",
      "regionName" : "Pays de la Loire"
    },
    {
      "departmentCode" : "45",
      "departmentName" : "Loiret",
      "regionCode" : "24",
      "regionName" : "Centre-Val de Loire"
    },
    {
      "departmentCode" : "46",
      "departmentName" : "Lot",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "47",
      "departmentName" : "Lot-et-Garonne",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "48",
      "departmentName" : "Lozère",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "49",
      "departmentName" : "Maine-et-Loire",
      "regionCode" : "52",
      "regionName" : "Pays de la Loire"
    },
    {
      "departmentCode" : "50",
      "departmentName" : "Manche",
      "regionCode" : "28",
      "regionName" : "Normandie"
    },
    {
      "departmentCode" : "51",
      "departmentName" : "Marne",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "52",
      "departmentName" : "Haute-Marne",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "53",
      "departmentName" : "Mayenne",
      "regionCode" : "52",
      "regionName" : "Pays de la Loire"
    },
    {
      "departmentCode" : "54",
      "departmentName" : "Meurthe-et-Moselle",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "55",
      "departmentName" : "Meuse",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "56",
      "departmentName" : "Morbihan",
      "regionCode" : "53",
      "regionName" : "Bretagne"
    },
    {
      "departmentCode" : "57",
      "departmentName" : "Moselle",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "58",
      "departmentName" : "Nièvre",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "59",
      "departmentName" : "Nord",
      "regionCode" : "32",
      "regionName" : "Nord-Pas-de-Calais-Picardie"
    },
    {
      "departmentCode" : "60",
      "departmentName" : "Oise",
      "regionCode" : "32",
      "regionName" : "Nord-Pas-de-Calais-Picardie"
    },
    {
      "departmentCode" : "61",
      "departmentName" : "Orne",
      "regionCode" : "28",
      "regionName" : "Normandie"
    },
    {
      "departmentCode" : "62",
      "departmentName" : "Pas-de-Calais",
      "regionCode" : "32",
      "regionName" : "Nord-Pas-de-Calais-Picardie"
    },
    {
      "departmentCode" : "63",
      "departmentName" : "Puy-de-Dôme",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "64",
      "departmentName" : "Pyrénées-Atlantiques",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "65",
      "departmentName" : "Hautes-Pyrénées",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "66",
      "departmentName" : "Pyrénées-Orientales",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "67",
      "departmentName" : "Bas-Rhin",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "68",
      "departmentName" : "Haut-Rhin",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "69",
      "departmentName" : "Rhône",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "70",
      "departmentName" : "Haute-Saône",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "71",
      "departmentName" : "Saône-et-Loire",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "72",
      "departmentName" : "Sarthe",
      "regionCode" : "52",
      "regionName" : "Pays de la Loire"
    },
    {
      "departmentCode" : "73",
      "departmentName" : "Savoie",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "74",
      "departmentName" : "Haute-Savoie",
      "regionCode" : "84",
      "regionName" : "Auvergne-Rhône-Alpes"
    },
    {
      "departmentCode" : "75",
      "departmentName" : "Paris",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "76",
      "departmentName" : "Seine-Maritime",
      "regionCode" : "28",
      "regionName" : "Normandie"
    },
    {
      "departmentCode" : "77",
      "departmentName" : "Seine-et-Marne",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "78",
      "departmentName" : "Yvelines",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "79",
      "departmentName" : "Deux-Sèvres",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "80",
      "departmentName" : "Somme",
      "regionCode" : "32",
      "regionName" : "Nord-Pas-de-Calais-Picardie"
    },
    {
      "departmentCode" : "81",
      "departmentName" : "Tarn",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "82",
      "departmentName" : "Tarn-et-Garonne",
      "regionCode" : "76",
      "regionName" : "Languedoc-Roussillon-Midi-Pyrénées"
    },
    {
      "departmentCode" : "83",
      "departmentName" : "Var",
      "regionCode" : "93",
      "regionName" : "Provence-Alpes-Côte d Azur"
    },
    {
      "departmentCode" : "84",
      "departmentName" : "Vaucluse",
      "regionCode" : "93",
      "regionName" : "Provence-Alpes-Côte dAzur"
    },
    {
      "departmentCode" : "85",
      "departmentName" : "Vendée",
      "regionCode" : "52",
      "regionName" : "Pays de la Loire"
    },
    {
      "departmentCode" : "86",
      "departmentName" : "Vienne",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "87",
      "departmentName" : "Haute-Vienne",
      "regionCode" : "75",
      "regionName" : "Aquitaine-Limousin-Poitou-Charentes"
    },
    {
      "departmentCode" : "88",
      "departmentName" : "Vosges",
      "regionCode" : "44",
      "regionName" : "Alsace-Champagne-Ardenne-Lorraine"
    },
    {
      "departmentCode" : "89",
      "departmentName" : "Yonne",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "90",
      "departmentName" : "Territoire de Belfort",
      "regionCode" : "27",
      "regionName" : "Bourgogne-Franche-Comté"
    },
    {
      "departmentCode" : "91",
      "departmentName" : "Essonne",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "92",
      "departmentName" : "Hauts-de-Seine",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "93",
      "departmentName" : "Seine-Saint-Denis",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "94",
      "departmentName" : "Val-de-Marne",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "95",
      "departmentName" : "Val-doise",
      "regionCode" : "11",
      "regionName" : "Ile-de-France"
    },
    {
      "departmentCode" : "971",
      "departmentName" : "Guadeloupe",
      "regionCode" : "01",
      "regionName" : "Guadeloupe"
    },
    {
      "departmentCode" : "972",
      "departmentName" : "Martinique",
      "regionCode" : "02",
      "regionName" : "Martinique"
    },
    {
      "departmentCode" : "973",
      "departmentName" : "Guyane",
      "regionCode" : "03",
      "regionName" : "Guyane"
    },
    {
      "departmentCode" : "974",
      "departmentName" : "La Réunion",
      "regionCode" : "04",
      "regionName" : "La Réunion"
    },
    {
      "departmentCode" : "976",
      "departmentName" : "Mayotte",
      "regionCode" : "06",
      "regionName" : "Mayotte"
    },
    {
      "departmentCode" : "987",
      "departmentName" : "Polynésie Française",
      "regionCode" : null,
      "regionName" : null
    },
    {
      "departmentCode" : "988",
      "departmentName" : "Nouvelle Calédonie",
      "regionCode" : null,
      "regionName" : null
    }
  ];


  ville:string= '';
  userName:any;
  favoris:any;
  activite:any;
  natures: any={};
  criteria: any;
  libelleApeDis: boolean;
  data;

  emptyPhoto="../../assets/images/avatars/user.jpg"

  constructor(private router:Router,private usrService:UserService, private fb: FormBuilder,
    private entService:EntrepriseService,private confirmationDialogService: ConfirmationDialogService,
    private storage: LocalStorageService,) {
      this.data={content:{}}
      this.data.content.profileUrl=this.emptyPhoto;
     }

  ngOnInit(): void {
    let madate=new Date(Date.now())

    let month:any=madate.getMonth()+1;
    if(!(month>9)){
      month="0"+month
    }
    let day:any=madate.getUTCDate();
    if(!(day>9)){
      day="0"+day
    }
    this.maxDate=""+madate.getUTCFullYear()+"-"+month+"-"+day;
    this.maxDate2=""+madate.getUTCFullYear()+"-"+month+"-"+day;
    this.maxDate
    this.confidentialite="false";
    this.usrService.identity().subscribe((data)=>{
      console.log(data.content.firstName);
      this.userName = data.content.firstName;
      this.data=data;
      if(!this.data.content.profileUrl){
        this.data.content.profileUrl=this.emptyPhoto


       }else{
        this.data.content.profileUrl=this.usrService.profilePictureUrl+"/"+ this.data.content.profileUrl
       }
       if(!this.data.content.companyUrl){
        this.data.content.companyUrl=this.emptyPhoto
       }else{
        this.data.content.companyUrl=this.usrService.profilePictureUrl+"/"+ this.data.content.companyUrl

       }

      if(data.content.subscriptionApe){
        this.libelleApeDis=true;
        this.codeApe=data.content.subscriptionApe;
      }
  })
    this.entService.getFavoris().subscribe((data)=>{
      this.favoris = data;
      console.log(this.favoris);
    })
    this.entService.getNature().subscribe((data)=>{
      this.natures = data;
      console.log(this.natures);
    })
    if (this.router.url === '/searchUpdate'){
      this.criteria  = this.storage.retrieve('criteria');
      this.codeApe =  this.criteria.codeApe;
      this.libelleApe =  this.criteria.libelleApe;
      this.chiffreAffaire =  this.criteria.chiffreAffaire;
      this.nature =  this.criteria.nature ;
      this.effectif =  this.criteria.effectif ;
      this.ville =  this.criteria.ville ;
      this.decision =  this.criteria.decision;
      this.chiffreAffaireMax= this.criteria.chiffreAffaireMax ;
      this.confidentialite= this.criteria.confidentialite ;
      this.decisionMax= this.criteria.decisionMax ;
      this.effectifMax= this.criteria.effectifMax ;
    }
  }

  update(){
    console.log("ville select");
    console.log(this.departements);
    console.log(this.ville);
    console.log("END");
  }
  update2(){
    console.log("nature select");
    console.log(this.natures.content);
    console.log(this.nature);
    console.log("END");
  }

  logout(){
    this.usrService.tokens=null;
    this.storage.clear();
    this.router.navigate(["/login"]);
  }

  saverange(){
    this.minDate2=this.decision;
    if(!this.decisionMax){
      let madate=new Date(Date.now())

      let month:any=madate.getMonth()+1;
      if(!(month>9)){
        month="0"+month
      }
      let day:any=madate.getUTCDate();
      if(!(day>9)){
        day="0"+day
      }
      this.decisionMax=""+madate.getUTCFullYear()+"-"+month+"-"+day;
    }
  }
  saverangeMinDate(){
    this.maxDate=this.decisionMax;
    if(!this.decision){

      this.decision="2020-01-01";
    }
  }
  saverange2(){
    this.maxCa=this.chiffreAffaire;
  }

  loadFavoris(codeApe,nature,libelleApe,chiffreAffaire,chiffreAffaireMax,ville,effectif,effectifMax,decision,decisionMax,confidentialite){
    console.log("NATURE: ", nature);
    let ca:number=+this.chiffreAffaire;
    let caMAx:number=+this.chiffreAffaireMax;
    if (ca >caMAx) {
       console.log(this.chiffreAffaire)
       this.showtoast2("le chiffre d'affaire ne peut être supérieur au chiffre d'affaire maximal")
       return;

    }
    let eff:number=+this.effectif;
    let effMax:number=+this.effectifMax;
    if (eff >effMax) {
      console.log(this.effectifMax)
      this.showtoast2("l'effectif maximal ne peut être inférieur à l'effectif")
      return;
    }

    this.clearAndSaveCriteria({codeApe:codeApe,nature:this.nature, libelleApe:libelleApe,chiffreAffaire:chiffreAffaire, chiffreAffaireMax:chiffreAffaireMax,ville:ville,
      confidentialite:confidentialite, effectif:effectif,effectifMax:effectifMax,decision:decision, decisionMax:decisionMax});
    this.router.navigate(['/entreprise/entreprise/search'])
  }
  loadFavoris2(codeApe,nature,libelleApe,chiffreAffaire,chiffreAffaireMax,ville,effectif,effectifMax,decision,decisionMax,confidentialite){
    console.log("NATURE: ", nature);
    let temp=[]
    if(nature){
      temp=nature.split(",");
    }
    let ca:number=+this.chiffreAffaire;
    let caMAx:number=+this.chiffreAffaireMax;
    if (ca >caMAx) {
      console.log(this.chiffreAffaire)
      this.showtoast2("le chiffre d'affaire ne peut être supérieur au chiffre d'affaire maximal")
      return;

    }
    let eff:number=+this.effectif;
    let effMax:number=+this.effectifMax;
    if (eff >effMax) {
      console.log(this.effectifMax)
      this.showtoast2("l'effectif maximal ne peut être inférieur à l'effectif")
      return;
    }

    this.clearAndSaveCriteria({codeApe:codeApe,nature:temp, libelleApe:libelleApe,chiffreAffaire:chiffreAffaire, chiffreAffaireMax:chiffreAffaireMax,ville:ville,
      confidentialite:confidentialite, effectif:effectif,effectifMax:effectifMax,decision:decision, decisionMax:decisionMax});
    this.router.navigate(['/entreprise/entreprise/search'])
  }

    showtoast2(message?){
      // Get the snackbar DIV
      var x = document.getElementById("snackbar2");
      if(message){
       x.innerHTML = message;
      }

      // Add the "show" class to DIV
      x.className = "show";

      // After 3 seconds, remove the show class from DIV
       setTimeout(function(){ x.className = x.className.replace("show", ""); }, 7000);
     }
     showtoast() {
       // Get the snackbar DIV
       var x = document.getElementById("snackbar");

       // Add the "show" class to DIV
       x.className = "show";

       // After 3 seconds, remove the show class from DIV
       setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
     }

    clearAndSaveCriteria(data){
      console.log("====================================")
      console.log(data)
      this.storage.clear('criteria');
      this.storage.store('criteria', data);
    }

    public openConfirmationDialogDel(title: string, message: string,id,i): void {
      this.confirmationDialogService
        .confirm(title, message)
        .then(confirmed => {
          console.log('User confirmed:', confirmed);
          if (confirmed) this.delFavori(id,i);
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    delFavori(id,i){
      this.entService.delFavoris(id).subscribe(()=>{
        console.log('delete favoris...')
        console.log(i)
        this.favoris.content.splice(i,1);
      })
    }
    reset(){
      this.decision=""
      this.decisionMax="";

      (<HTMLFormElement>document.getElementById("myForm")).reset()
      if(this.libelleApeDis){
        (<HTMLInputElement>document.getElementById("codeApe")).value=this.codeApe;

      }
    }

}
