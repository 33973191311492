import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jhi-app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() title: any;
  @Input() message: any;
  @Input() btnOkText: any;
  @Input() btnCancelText: any;

  constructor(private activeModal: NgbActiveModal) {}

  // eslint-disable-next-line @typescript-eslint/tslint/config
  // tslint:disable-next-line:typedef
  ngOnInit() {}

  // eslint-disable-next-line @typescript-eslint/tslint/config
  // tslint:disable-next-line:typedef
  public decline() {
    this.activeModal.close(false);
  }

  // eslint-disable-next-line @typescript-eslint/tslint/config
  // tslint:disable-next-line:typedef
  public accept() {
    this.activeModal.close(true);
  }

  // eslint-disable-next-line @typescript-eslint/tslint/config
  // tslint:disable-next-line:typedef
  public dismiss() {
    this.activeModal.dismiss();
  }
}
