import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {LocalStorageService} from 'ngx-webstorage';
import { UserService } from '../services/user-service.service';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  error=false;

  constructor(private fb: FormBuilder, private usrSer:UserService,
              private storage: LocalStorageService, private router: Router,) { }

  ngOnInit(): void {
     
  }
  logout(){
    this.router.createUrlTree(['/accountcreate']);
    console.log( this.router.getCurrentNavigation())
    console.log(this.router.navigateByUrl("/accountcreate")) 
    console.log(this.router.navigate(["accountcreate"],{preserveFragment:true}))

  } 

  login():void{
    this.loading = true
   this.usrSer.login({email: this.loginForm.get('username')!.value,
                      password: this.loginForm.get('password')!.value,}).subscribe((data:any)=>{
                        this.loading = false
                     console.log(data.content.jwttoken)
                     this.clearAndSaveToken(data.content.jwttoken);
                   /*  this.usrSer.fetch().subscribe((data)=>{
                       console.log(data);
                       if(data.content.profile === "SuperAdmin"){this.router.navigate(['/dashboard']);}
                       if(data.content.profile === "SuperAdmin"){this.router.navigate(['/dashboard']);}
                       if(data.content.profile === "User"){this.router.navigate(['/dashboard']);}
                      
                     }) */
                     this.router.navigate(['/dashboard']);
                      },
                      (err)=>{
                        console.log(err)
                      

                        this.loading = false;
                        this.error = true;
                        if(err && err.error && err.error.message)
                        {
                          var x = document.getElementById("error");  
                          
                            x.innerHTML = err.error.message;
                           

                        }
                      }) 
  }

  clearAndSaveToken(token){
    this.usrSer.tokens=token;
    this.storage.clear('token');
    this.storage.store('token', token);
  }

}