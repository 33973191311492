import { Component, OnInit, TemplateRef, ViewChild,AfterViewInit } from '@angular/core';
import { UserService } from '../services/user-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EntrepriseService } from '../services/entreprise.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ConfirmationDialogService } from '../services/confirmation-dialog/confirmation-dialog.service';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';
import { loadStripe } from '@stripe/stripe-js';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FormControl, FormGroup, Validators } from '@angular/forms';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { CropperOptions } from 'ngx-cropperjs-wrapper';



const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;

  public fileInput: File = null;
  public options = {
    minCropWidth: 50, // Implemented in wrapper (not supported in cropper.js)
    minCropHeight: 50, // Implemented in wrapper (not supported in cropper.js)
    movable: true,
    scalable: false,
    zoomable: true,
    center:true,
    viewMode: 1,
    aspectRatio: 1,
  } as CropperOptions;
  data:any;
  stripePromise: any = null;
  stripe: any = null;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  userName: any;
  ID: any;
  userData: any;
  id: any;
  civilite:any="Monsieur";
  prenom:any;
  nom:any;
  Datedenaissance:any;
  activite:any;
  password:any="";
  password2:any="";
  factures:any;
  activites:any={};
  subscription: any;
  message: any;
  questionType:String="autres";
  carte="**** **** **** ****";

  societyError=false;

	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.Switzerland];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
  });
  phoneForm2 = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
  });
  emptyPhoto="../../assets/images/avatars/user.jpg"
  fileOutput: File;
  format: any;
  constructor(private route: ActivatedRoute ,private usrService:UserService, private router:Router,
    private entService:EntrepriseService,  private storage: LocalStorageService, private confirmationDialogService: ConfirmationDialogService,) {

      this.stripePromise = loadStripe('pk_test_51HvfjMIIm1dUuideT1WXqIVCQR3BVroCBegfEXvhdiXZGIAAoGYddJx6DOzCPZDnXYkgjalqbVJRIVayY98iQnB200VDL1JxPG')
      .then(res => {
        this.stripe = res;
        console.log(res)
      });
      this.userData={content:{}}
      this.userData.content.profileUrl=this.emptyPhoto;
     }

  ngOnInit(): void {

    this.usrService.identity().subscribe((data)=>{
      console.log("DATA CONTENT : ",data.content);
      this.userName = data.content.firstName;
      this.id = data.content.id;

      this.usrService.findById(data.content.id).subscribe((data:any)=>{
        console.log(data);
       this.userData = data;
       console.log(this.usrService.profilePictureUrl)
       console.log( this.userData.content.profileUrl)
       if(!this.userData.content.profileUrl){
        this.userData.content.profileUrl=this.emptyPhoto
       }else{
        this.userData.content.profileUrl=this.usrService.profilePictureUrl+"/"+ this.userData.content.profileUrl
       }
       if(!this.userData.content.companyUrl){
        this.userData.content.companyUrl=this.emptyPhoto
       }else{
        this.userData.content.companyUrl=this.usrService.profilePictureUrl+"/"+ this.userData.content.companyUrl

       }

       if (!(this.userData.content.phone.includes("+"))) {
        this.userData.content.phone="+"+this.userData.content.phone

       }
       if(this.userData.content.card){
         this.carte=this.userData.content.card

       }
       this.phoneForm.get("phone").setValue(this.userData.content.phone)
       this.phoneForm2.get("phone").setValue(this.userData.content.entreprisePhone)



       if(this.userData.content.naissance){

        //this.userData.content.naissance=this.userData.content.naissance.substring(0,10);
        console.log(new Date(this.userData.content.naissance))
        let date=new Date(this.userData.content.naissance);

        let month:any=date.getMonth()+1;
        if(!(month>9)){
          month="0"+month
        }
        let day:any=date.getUTCDate();
        if(!(day>9)){
          day="0"+day
        }
        this.userData.content.naissance=""+date.getUTCFullYear()+"-"+month+"-"+day;
        console.log(this.userData.content.naissance)


       }
       if(this.userData.content.entrepriseCreation){

        //this.userData.content.naissance=this.userData.content.naissance.substring(0,10);
        console.log(new Date(this.userData.content.entrepriseCreation))
        let date=new Date(this.userData.content.entrepriseCreation);

        let month:any=date.getMonth()+1;
        if(!(month>9)){
          month="0"+month
        }
        let day:any=date.getUTCDate();
        if(!(day>9)){
          day="0"+day
        }
        this.userData.content.entrepriseCreation=""+date.getUTCFullYear()+"-"+month+"-"+day;
        console.log(this.userData.content.entrepriseCreation)


       }

      });
});


    this.entService.getActivites().subscribe((data)=>{
      this.activites.content=data.content;

     /*  for (let index = 0; index < data.content.length; index=index+100) {
        this.activites.content.push( data.content[index]) ;

      } */
      //this.activites = data;
      console.log(this.activites);
    })

    this.entService.getActivites().subscribe((data)=>{
      this.activites=data;
     /*  for (let index = 0; index < data.content.length; index=index+100) {
        this.activites.content.push( data.content[index]) ;

      } */
      //this.activites = data;
      console.log(this.activites);
    })

    this.usrService.getAllSub().subscribe((data:any)=>{
      this.factures=data.content;
     /*  for (let index = 0; index < data.content.length; index=index+100) {
        this.activites.content.push( data.content[index]) ;

      } */
      //this.activites = data;
      console.log(this.factures);
    })

    this.usrService.getCurrentSub().subscribe((data:any)=>{
      this.subscription=data.content;
      console.log(this.subscription)
    /*   this.subscription.expirationDate=new Date( this.subscription.paymentDate)
      this.subscription.expirationDate.setFullYear(this.subscription.expirationDate.getFullYear() + 1)
      this.subscription.expirationDate.setDate(this.subscription.expirationDate.getDate() - 1)
      this.subscription.expirationDate.setMonth (this.subscription.expirationDate.getMonth() - 1)  */
      /*  for (let index = 0; index < data.content.length; index=index+100) {
        this.activites.content.push( data.content[index]) ;

      } */
      //this.activites = data;
      console.log(this.activites);
    })

    document.getElementById("profil").style.display = "block";
    this.makeAccordion()
  }

  portail(){

    this.usrService.getPortal().subscribe((data:any)=>{
      console.log(data)
      //this.subscription=data.content;

      //this.router.navigate([data.content]);
      document.location.href = data.content;


    })
  }

  portail2() {

    this.usrService.getPortal2(this.id).subscribe((data:any)=>{
      console.log(data)
      this.stripe.redirectToCheckout({sessionId: data.content}
        )
  .then(res => {
     console.log(res);
  })
  .catch(error => {
     console.log(error);
  });
    })
  };
  makeAccordion(){
    var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}
  }
  contactUs(){
    console.log(this.message)
    this.usrService.contactUs(this.message,this.questionType).subscribe((data)=>{


      this.showtoast3()
      this.message=null;

    });

  }
  public openConfirmationDialogDel(title: string, message: string,id,i): void {
    this.confirmationDialogService
      .confirm(title, message)
      .then(confirmed => {
        console.log('User confirmed:', confirmed);
        if (confirmed)
        console.log("confirmed");
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  onFilePick(format,event: any) {
    this.format=format;
    // Feed selected file to cropper
    this.fileInput = event.target.files.item(0);
    console.log(event.target.files.item(0).name)
    var x = document.getElementById("imageCropper2");
    x.className = "show";
    x.addEventListener("blur", this.hide, true);

  }

  onFail(error) {
    this.showtoast2("L'image selectionnée ne respecte ps le format ou la taille minimale requise!");
    console.error("============================="+error);
  }

  onFileChange(file: File) {
    // TODO: upload file to backend
    console.log(file.size)
    this.fileOutput=file;



    // After 3 seconds, remove the show class from DIV
    //setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
  crop(){
    console.log(this.data)
    let xa = document.getElementById("imageCropper2");




    // After 3 seconds, remove the show class from DIV
    // xa.className.replace("show", "")
     setTimeout(function(){ xa.className = xa.className.replace("show", ""); }, 1000);
     console.log(xa)
     console.log( this.fileOutput)
     this.upload(this.format, this.fileOutput)
  }
  upload(format,file){
   // console.log(event)


   // const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

        let myFile  = new File([file], "name.png");
        console.log(myFile);
        this.loading=true;
        //let result=reader.result.toString().replace("data:image/jpeg;","")
        this.usrService.updateFile(myFile,format).subscribe((data)=>{

          console.log(data)
          let photos=data.content.split("/")
          if(format==1){
            this.userData.content.profileUrl=this.emptyPhoto;
            let datas=this.userData
            let service=this.usrService
            setTimeout(function(){  datas.content.profileUrl=service.profilePictureUrl+"/"+ photos[photos.length-1] }, 1000);
            this.loading = false

          }
          if(format==2){
            this.userData.content.companyUrl=this.emptyPhoto;
            let datas=this.userData
            let service=this.usrService
            setTimeout(function(){  datas.content.companyUrl=service.profilePictureUrl+"/"+ photos[photos.length-1] }, 1000);
            this.loading = false
          }

        },(err)=>{
          this.loading = false
          console.log(err)
          this.showtoast2("erreur lors de l'enregistrement du fichier Veuillez choisir un autre fichier")

        })
    };


  }

  hide(){
    console.log(this.data)
    let xa = document.getElementById("imageCropper2");

     setTimeout(function(){ xa.className = xa.className.replace("show", ""); }, 1000);
  }
  blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}
  deleteFile(type){
  let file=""
    if(type==1){
      file=this.userData.content.profileUrl.replace(this.usrService.profilePictureUrl+"/","")
    }
    if(type==2){
      file=this.userData.content.companyUrl.replace(this.usrService.profilePictureUrl+"/","")
    }
        //let result=reader.result.toString().replace("data:image/jpeg;","")
        this.usrService.deleteFile(file).subscribe((data)=>{
          console.log(data)
          this.showtoast2("Image supprimée avec succès !")
          if(type==1){
            this.userData.content.profileUrl=this.emptyPhoto
          }
          if(type==2){
          this.userData.content.companyUrl=this.emptyPhoto
          }
        },(err)=>{
          this.loading = false
          console.log(err)

        })



  }
  generatePdf(data){
    console.log(data)
    let typePaiement="mensuel"
    data.amounts=data.amount;
    data.tvas=data.subscriptionId.tva;
    if(data.annual) {
      typePaiement="annuel";
      data.amounts=data.subscriptionId.annualAmount;
      data.tvas=data.subscriptionId.annualTva;
    }
    let entrepriseCp="";
    if(this.userData.content.entrepriseVoie) entrepriseCp=entrepriseCp+this.userData.content.entrepriseVoie
    if(this.userData.content.entrepriseCp){
      if(this.userData.content.entrepriseRue){
        entrepriseCp=entrepriseCp +" "+this.userData.content.entrepriseRue+"\n"
      }
      entrepriseCp=entrepriseCp+this.userData.content.entrepriseCp
    }


    let entrepriseVille=""
    this.userData.content.nom_prenom=""
    if(this.userData.content.entrepriseVille) entrepriseVille=entrepriseVille+this.userData.content.entrepriseVille
    if(!this.userData.content.entreprise){
      this.userData.content.nom_prenom=this.userData.content.firstName+ ' '+this.userData.content.lastName
    }else{
      this.userData.content.nom_prenom+=this.userData.content.entreprise+"\n"+this.userData.content.firstName+ ' '+this.userData.content.lastName

    }
    if(data.expirationDate){

      let date=new Date(data.expirationDate);

      let month:any=date.getMonth()+1;
      if(!(month>9)){
        month="0"+month
      }
      let day:any=date.getUTCDate();
      if(!(day>9)){
        day="0"+day
      }
      data.expirationDates=""+day+"/"+month+"/"+date.getUTCFullYear();

     }
     if(data.paymentDate){

      let date=new Date(data.paymentDate);

      let month:any=date.getMonth()+1;
      if(!(month>9)){
        month="0"+month
      }
      let day:any=date.getUTCDate();
      if(!(day>9)){
        day="0"+day
      }
      data.paymentDates=""+day+"/"+month+"/"+date.getUTCFullYear();

     }

    //let img1=Chartist.createSvg(document.getElementsByClassName("ct-chart")[0],"200px","200px","mysvg").
    //let img1=document.getElementsByClassName("ct-chart")[0].getElementsByClassName("ct-chart-pie")[0]
    // console.log(img1)
      var docDefinition = {
       footer: {
        alignment: 'center',
        border: [false, true, false, false],
        fontSize: 7,
        color:"#9e9e9d",

         columns: [
           'Kairos est une marque de VEILLE CROISSANCE EXTERNE - 13 Rue Baron 75017 PARIS - France\n'+
           'SAS au capital de 2 000,00 € - RCS PARIS 889 931 366 - Code APE 6202A - N°TVA FR60889931366\n'+
           'Facture ('+data.reference+') – Page 1/1'
         ]
       },
       header:        [],
       content: [
         {
           alignment: 'justify',
           style: 'header',
           columns: [

             {
               alignment: 'left',
               color:"#0070C0",
               text:'KAIROS'
               }
           ],
           //pageBreak: 'after'

         }
    ,  {
      text:"\n"
    },
    {
      style: 'header',
      table: {
          widths:[ 200, 100, '*' ],
          body: [
              [{
                      border: [false, false, false, false],
                      fillColor: '#E6ECF5',
                      margin: [0, 3, 0, 3],
                      fontSize:9.5,
                      color:"#00185E",
                      bold:false,
                      text:[
                        'Référence de la facture : ',{text:data.reference+"\n",
                        color:"#00185E",
                        margin: [0, 2, 0, 2],
                        bold:true},
                        'Type de facture  : ',{text:"Souscription\n", color:"#00185E",bold:true,margin: [0, 2, 0, 2]},
                        'Date d\'émission  : ',{text:data.paymentDates+"\n", color:"#00185E",bold:true,margin: [0, 2, 0, 2],},
                        'Identifiant Client : ',{text:this.userData.content.email+"\n", color:"#00185E",bold:true,margin: [0, 2, 0, 2],},

                      ],
                          },{
                            border: [false, false, false, false],
                            text:""

                          },{
                            border: [false, false, false, false],
                            fontSize:9.5,
                            color:"#00185E",
                              alignment: 'left',
                              text:this.userData.content.nom_prenom+"\n"+
                              entrepriseCp+" "+ entrepriseVille
                          }]
          ]
      }
  },
  {
    text:"\n"
  },
  {
    text:"\n"
  },
  {
    text:"\n"
  },
  {
    columns: [

      {
        width:  300,
        style: 'header',
        table: {
          widths:[ '*' ],
          body: [

                [{

                        border: [false, false, false, false],
                        fillColor: '#E6ECF5',
                        fontSize:9.5,
                        color:"#00185E",
                        margin: [0, 4, 0, 3],
                        text:[
                          'Facture n°',{text:data.reference+" du "+data.paymentDates, color:"#00185E"},

                        ],
                            }],
                    [{
                      border: [false, false, false, true],
                      fillColor: '#FFFFFF',
                      fontSize:9.5,
                      bold:false,
                      color:"#00185E",
                      borderColor: ['#ababab', '#ababab', '#ababab', '#ababab'],
                      margin: [0, 2, 0, 2],
                      text:[
                        'Total de la facture HT \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t' + data.amounts+" €",

                      ],
                          }],
                  [{
                    border: [false, false, false, true],
                    fillColor: '#FFFFFF',
                    fontSize:9.5,
                    bold:false,
                    color:"#00185E",
                    borderColor: ['#ababab', '#ababab', '#ababab', '#ababab'],
                    margin: [0, 2, 0, 2],
                    text:[
                      'TVA (20%)   \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'+data.tvas +" €",

                    ],
                        }],
                        [{
                          border: [false, false, false, true],
                          fillColor: '#FFFFFF',
                          hLineColor:'#123456',
                          fontSize:9.5,
                          bold:false,
                          color:"#00185E",
                          borderColor: ['#ababab', '#ababab', '#ababab', '#ababab'],
                          margin: [0, 2, 0, 2],
                          text:[
                            'Total taxes \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t '+ data.tvas +" €",

                          ],
                              }],
                  [{
                    border: [false, false, false, true],
                    fillColor: '#F5F5F5',
                    fontSize:9.5,
                    bold:false,
                    color:"#00185E",
                    borderColor: ['#00185E', '#00185E', '#00185E', '#00185E'],
                    margin: [0, 2, 0, 2],
                    text:[
                      'Total de la facture TTC   \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'+ (data.tvas+0+data.amounts+0) +" €",

                    ],
                        }]

            ]
        }
    },

    {
      style: 'header',
      width: "*",
      table: {
        widths:[ '*' ],
        body: [
              [{
                            fillColor: '#E6ECF5',
                            border: [false, false, false, false],
                            fontSize:9.5,
                            color:"#00185E",
                              alignment: 'left',
                              bold:true,
                              margin: [0, 2, 0, 2],
                              text:[{
                                bold:true,
                                text:'Information de paiement:\n ',
                                margin: [0, 2, 0, 2],

                              },
                              {
                                bold:false,
                                text:'Le montant de '


                              },{
                                text:(data.tvas+0+data.amounts+0)+"€",
                                bold:true,

                              },{
                                bold:false,
                                text:' a été prélevé sur votre moyen de paiement par défaut.'


                              }

                              ]
                            //  text:'Information de paiement:\n Le montant de '+data.amount
                            //  +'€ a été prélevé sur votre moyen de paiement par défaut.'
                          }]
          ]
      }
  }
    ]


   },
   {
    text:"\n"

  },
   {
    fontSize:11,
    bold:true,
      alignment: 'left',
      color:"#00185E",
    text:"Rubrique divers"

  },
  {
    style: 'header',
    table: {
        widths:[  '*' ],
        body: [
            [
              {
                    border: [false, false, false, false],

                    fillColor: '#E6ECF5',
                    fontSize:9.5,
                    color:"#00185E",
                    bold:true,
                    columns: [
                      {
                        width:  280,
                        text: 'Spécial',
                        margin: [40, 3, 0, 3],
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: '*',
                        margin: [0, 3, 0, 3],
                        text: 'Quantité'
                      },
                      {
                        // fixed width
                        width: '*',
                        margin: [0, 3, 0, 3],
                        text: 'Prix unitaire'
                      },
                      {
                        // % width
                        width: '*',
                        margin: [0, 3, 0, 3],
                        alignment: 'right',
                        text: 'Prix HT'
                      }
                    ],
                  }
                ]]}},
      {
        style: 'header',
        table: {
            widths:[  '*' ],
            body: [
                [{
                        border: [false, false, false, true],
                        borderColor: ['#aaaaaa', '#aaaaaa', '#aaaaaa', '#aaaaaa'],
                        fillColor: '#FFFFFF',
                        fontSize:9.5,
                        color:"#00185E",
                        bold:false,
                        columns: [
                          {
                            width:  280,
                            fontSize:8.5,
                            bold:false,
                            margin: [0, 0, 0, 20],
                            color:"#4D5592",
                            text: 'Abonnement '+data.subscriptionId.libelle+ " avec paiement "+typePaiement+"\n"+
                            "jusqu’au "+ data.expirationDates
                          },
                          {
                            // star-sized columns fill the remaining space
                            // if there's more than one star-column, available width is divided equally
                            width: '*',
                            color:"#4D5592",
                            text: '1',
                            margin: [0, 10, 0, 10]
                          },
                          {
                            // fixed width
                            width: '*',
                            color:"#4D5592",
                            margin: [0, 10, 0, 10],
                            text: data.amounts+' €'

                          },
                          {
                            // % width
                            width: '*',
                            color:"#4D5592",
                            alignment: 'right',
                            margin: [0, 10, 0, 10],
                            text:data.amounts+' €'
                          }
                        ],
                      }]]}},
  {
    style: 'header',
    table: {
        widths:[  '*' ],
        body: [
            [{
                    border: [false, false, false, false],
                    fillColor: '#F5F5F5',
                    margin: [0, 3, 0, 3],
                    fontSize:9.5,
                    color:"#00185E",
                    bold:true,
                    columns: [
                      {
                        width:  280,
                        text: ' '
                      },
                      {
                        // star-sized columns fill the remaining space
                        // if there's more than one star-column, available width is divided equally
                        width: '*',
                        text: 'Sous TOTAL',
                        margin: [20, 0, 0, 0],
                      },

                      {
                        // % width
                        width: '*',
                        alignment: 'right',
                        fontSize:9.5,
                        bold:true,
                        text: data.amounts+' €'
                      }
                    ],
                  }]]}
                } ,


                  {
                    border: [false, false, false, false],
                    fillColor: '#E6ECF5',
                    fontSize:9.5,
                    color:"#00185E",
                    bold:true,
                    columns: [
                      {
                        width:  280,
                        text: ' '
                      },
                      {
                        style: 'header',
                        table: {
                            widths:[  '*' ],
                            body: [
                                [{
                                        border: [false, true, false, true],
                                        fillColor: '#FFFFFF',

                                        borderColor: ['#aaaaaa', '#aaaaaa', '#aaaaaa', '#aaaaaa'],
                                        fontSize:9.5,
                                        color:"#00185E",
                                        bold:true,
                                        columns: [
                                          {
                                            margin: [10, 20, 0, 3],
                                            width:  "*",
                                            text: 'Prix HT'
                                          },
                                          {
                                            // star-sized columns fill the remaining space
                                            // if there's more than one star-column, available width is divided equally
                                            width: '*',
                                            margin: [10, 20, 0, 3],
                                            alignment: 'right',
                                            text: data.amounts + ' €'
                                          }
                                        ],
                                      }],
                                      [{
                                        border: [false, false, false, false],
                                        fillColor: '#FFFFFF',
                                        fontSize:9.5,
                                        color:"#00185E",
                                        bold:true,
                                        columns: [
                                          {
                                            width:  "*",
                                            margin: [10, 3, 0, 3],
                                            border: [false, false, false, true],
                                            borderColor: ['#aaaaaa', '#aaaaaa', '#aaaaaa', '#aaaaaa'],
                                            text: 'TVA (20 %)'
                                          },
                                          {
                                            // star-sized columns fill the remaining space
                                            // if there's more than one star-column, available width is divided equally
                                            width: '*',
                                            margin: [0, 3, 0, 3],

                                            alignment: 'right',
                                            text: data.amounts*20/100 +" €"
                                          }
                                        ],
                                      }],
                                      [{
                                        border: [false, false, false, true],
                                        borderColor: ['#00185E', '#00185E', '#00185E', '#00185E'],
                                        fillColor: '#F5F5F5',
                                        fontSize:9.5,
                                        color:"#00185E",
                                        bold:true,
                                        columns: [
                                          {
                                            width:  "*",
                                            text: 'Total TTC',
                                            margin: [10, 3, 0, 3],
                                            border: [false, false, false, true],
                                            borderColor: ['#00185E', '#00185E', '#00185E', '#00185E'],
                                          },
                                          {
                                            // star-sized columns fill the remaining space
                                            // if there's more than one star-column, available width is divided equally
                                            width: '*',
                                            alignment: 'right',
                                            margin: [0, 3, 0, 3],
                                            text: (data.amounts*20/100+data.amounts) +" €"
                                          }
                                        ],
                                      }]

                                    ]}
                                    }
                    ],
                  },
                  {
                    text:"\n\n\n"

                  },
                  {
                    fontSize:7,
                    bold:false,
                    text: 'Pas d’escompte\n '+
                    " Tout incident de paiement peut entraîner une suspension de services et est passible de pénalités de retard calculées sur la base de trois fois le taux d’intérêt légal en vigueur en France, avec un montant d’indemnité forfaitaire minimum de 40 euros  ",

                  },


        /*  {
          columns: [

            {
              alignment: 'left',

              text:[
                'Référence de la facture :',{text:data.reference+"\n", color:"#00185E"},
                'Type de facture  :',{text:"Souscription\n", color:"#00185E"},
                'Date d\'émission  :',{text:data.paymentDate+"\n", color:"#00185E"},
                'Identifiant Client :',{text:this.userData.content.email+"\n", color:"#00185E"},

              ],

              },


            {
              alignment: 'left',
              text:'ENTREPRISE\n'+this.userData.content.firstName+ ' '+this.userData.content.lastName
             }
          ]


         }, */
         {
           text:"\n\n"

         },
          {
           alignment: 'center',
           columns: [

/*              {

               image: this.img,
               width:250
               },


             {
               image: this.img2,
               width:250
             } */
           ]

         },
         {
           text:"\n\n"

         },

       //  this.table(this.sortedStudents, ['id','nom','prenom', 'sexe','regime','dateDeNaissance']),

       ],
       styles: {
         header: {
           fontSize: 14,
           bold: true
         },
         title: {
           fontSize: 18,
           bold: true,
           background:"#123456",
           color:"#FFFFFF",
           alignment: 'center',

         },

         bigger: {
           fontSize: 15,
           italics: true
         }
       },
       defaultStyle: {
         columnGap: 20,
       }
     };

   pdfMake.createPdf(docDefinition).open();
  }
  updateUser(){
    this.societyError = false;
    if(!this.userData.content.entreprise || this.userData.content.entreprise == "" || !this.userData.content.entrepriseApe || this.userData.content.entrepriseApe == ""){
      this.societyError = true;
    }else{
      if(this.phoneForm.get("phone").value)
        this.userData.content.phone=this.phoneForm.get("phone").value.internationalNumber.replace(/\s+/g, '');

      if(this.phoneForm2.get("phone").value)
        this.userData.content.entreprisePhone=this.phoneForm2.get("phone").value.internationalNumber.replace(/\s+/g, '');


      this.loading = true
      this.usrService.updateUser(this.id,this.userData.content).subscribe((data)=>{
        console.log(data)
        this.loading = false
        this.showtoast()
      },(err)=>{
        this.loading = false
        this.showtoast2(err.error.message);

      })
    }
  }
  updatePassword(){
    console.log("eeeeeee");
    console.log(this.password2)
    if(!(this.password2.length>0)){
      console.log("aaaaaaaaaaaaaaaaaa");

      this.showtoast2();
      return;
    }
    this.loading = true

    this.usrService.updatePassword(this.password2).subscribe((data)=>{
      console.log(data)
      this.loading = false
      this.showtoast()


    },error=>{
      this.loading = false
      this.showtoast2(error.error.message);


    })

  }
  logout(){
    this.usrService.tokens=null;
    this.storage.clear();
    this.router.navigate(["/login"]);
  }

  ngAfterViewInit() {
    this.openCity('profil')
    document.getElementById("profil").style.display = "block";




  }
  showtoast2(message?){
    // Get the snackbar DIV
    var x = document.getElementById("snackbar2");
    if(message){
     x.innerHTML = message;
    }

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
     }
  showtoast() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }
    showtoast3() {
    var x = document.getElementById("snackbar3");

    x.className = "show";

    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

   openCity(cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName+"btn").className += " active";
  }

}
