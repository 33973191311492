import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const BASE_URL = 'https://app.kairos-opportunities.com/kairos/api/v1';
// const BASE_URL = 'http://34.163.92.238:8080/api/v1';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseService {
   resourceUrl1 = BASE_URL + '/company/total';
   resourceUrl100 = BASE_URL + '/company/week/total';
   resourceUrl1000 = BASE_URL + '/company/week/categorie';

  resourceUrl2  = BASE_URL + '/company/favoris'; // favoris
  resourceUrl2000  = BASE_URL + '/company/lastFavoris'; // favoris

  resourceUrl200  = BASE_URL + '/company/libelles'; // favoris
  resourceUrl201  = BASE_URL + '/company/activities'; // favoris

  resoureUrl3 = BASE_URL + '/company/all';  //all
  resoureUrl300 = BASE_URL + '/company/week';  //week

  resourceUrl4 = BASE_URL + '/company/find'; //one by id
  resourceUrl5 = BASE_URL + '/company/search'; //post multi-criteria
  resourceUrl500 = BASE_URL + '/company/freeSearch'; //post multi-criteria
  resourceUrl5000 = BASE_URL + '/company/publicSearch';
  resourceUrl501 = BASE_URL + '/stat/default'; //post multi-criteria
  resourceUrl502 = BASE_URL + '/stat/byYear'; //post multi-criteria
  resourceUrl503 = BASE_URL + '/stat/byMonth';
  resourceUrl504 = BASE_URL + '/stat/publicStat'; //post multi-criteria
  resourceUrl6 = BASE_URL + '/company/saveSearch'; //save search
  resourceUrl7 = BASE_URL + '/company/deleteFavoris'; //delete favori
  resourceUrl700 = BASE_URL + '/stat/histo'; //delete favori


/*
   resourceUrl1 = ' https://prod.veille.site:8443/api/v1/company/total';
  resourceUrl2  = ' https://prod.veille.site:8443/api/v1/company/favoris'; //favoris
  resoureUrl3 = ' https://prod.veille.site:8443/api/v1/company/all';  //all
  resourceUrl4 = ' https://prod.veille.site:8443/api/v1/company/find'; //one by id
  resourceUrl5 = ' https://prod.veille.site:8443/api/v1/company/search'; //post multi-criteria
  resourceUrl6 = ' https://prod.veille.site:8443/api/v1/company/saveSearch'; //save search
  resourceUrl7 = ' https://prod.veille.site:8443/api/v1/company/deleteFavoris'; //delete favori
    */
  constructor(private http: HttpClient, private storage: LocalStorageService) { }

  getNumberEnt(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl1 ,httpOptions);
  }
  getNumberweek(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl100 ,httpOptions);
  }
  getNumberweekCat(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl1000 ,httpOptions);
  }
  getHisto(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl700 ,httpOptions);
  }


  getFavoris(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl2 ,httpOptions);
  }
  getLastFavoris(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl2000 ,httpOptions);
  }
  getActivites(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl201 ,httpOptions);
  }
  getActivites2(){
    //const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       //Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl201 ,httpOptions);
  }

  getNature(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl200 ,httpOptions);
  }

  getAll(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resoureUrl3 ,httpOptions);
  }
  getWeek(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resoureUrl300 ,httpOptions);
  }

  findById(id){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl4}/${id}`, httpOptions);
  }

  findByMultiCriteria(criteria){
    if(criteria.nature){
      console.log(criteria.nature)
      let temp=[...criteria.nature];
      console.log(temp)
      criteria.nature="";
      for (let index = 0; index < temp.length; index++) {
        criteria.nature=temp[index]+","+criteria.nature;

      }
    }
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.post<any>(this.resourceUrl5, criteria, httpOptions);
  }
  freeSearch(key){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.post<any>(this.resourceUrl500, {key}, httpOptions);
  }
  publicSearch(key){
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
    })
   }
    return this.http.get<any>(this.resourceUrl5000+"/"+key, httpOptions);
  }
  publicAnalytics(key){
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
    })
   }
    return this.http.get<any>(this.resourceUrl504+"/"+key, httpOptions);
  }
  weekDetails(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.get<any>(this.resourceUrl501, httpOptions);
  }
  monthDetails(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.get<any>(this.resourceUrl503, httpOptions);
  }
  yearDetails(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.get<any>(this.resourceUrl502, httpOptions);
  }

  saveCriteria(criteria){
    if(criteria.nature){
      console.log(criteria.nature)
      let temp=[...criteria.nature];
      console.log(temp)
      criteria.nature="";
      for (let index = 0; index < temp.length; index++) {
        criteria.nature=temp[index]+","+criteria.nature;

      }
    }

    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.post<any>(this.resourceUrl6, criteria, httpOptions);
  }

  delFavoris(id){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.delete(`${this.resourceUrl7}/${id}`, httpOptions);
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + ''+ EXCEL_EXTENSION);
  }
}

