import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from '../../../projects/ngx-loading/src/public_api';
import { LocalStorageService } from 'ngx-webstorage';
import { UserService } from '../services/user-service.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {

  @ViewChild('ngxLoading',{ static: false }) ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild('customLoadingTemplate',{ static: false }) customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config =
   { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, 
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  resetForm = this.fb.group({
    password: ['', Validators.required],
    confirmPass: ['', Validators.required]
  });

  error=false;
  doNotMatch=false;
  success=false;
  key: any;
  initialized: boolean;
  errorMessage: any;

  constructor(private fb: FormBuilder, private usrSer:UserService,
    private storage: LocalStorageService, private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.key = params['token'];
      }
      this.initialized = true;
    });
  }

  changePass(){ 
    this.loading = true;
    this.error = false;
    this.doNotMatch = false;
    this.success = false;
if(this.resetForm.get('confirmPass').value !== this.resetForm.get('password').value){
   this.doNotMatch = true;
   this.loading = false;
} else{
  this.usrSer.changeResetPass({
    password: this.resetForm.get('password').value,
    token: this.key
  }).subscribe((data)=>{
    console.log(data);
    this.loading = false;
    this.success = true;
    let route=this.router
   
    setTimeout(function(){  route.navigate(['/login']) }, 2000);


 },(err)=>{
   this.loading = false;
   this.error = true;
   this.errorMessage=err.error.message
   console.log(err);


 }
 )
}
   

  }

}
