import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy  } from '@angular/core';

import { Subscription } from 'rxjs';
import { UserService } from './services/user-service.service';


@Directive({
  selector: '[appNgAuthority]'
})
export class NgAuthorityDirective implements OnDestroy{

  private authorities: string[] = [];
  private authenticationSubscription?: Subscription;

  constructor(private usrService:UserService,  private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) { }

  @Input()
  set appNgAuthority(value: string | string[]) {
    
    this.authorities = typeof value === 'string' ? [value] : value;
    this.updateView();
    // Get notified each time authentication state changes.
    this.authenticationSubscription = this.usrService.getAuthenticationState().subscribe(() => this.updateView());
  }

  ngOnDestroy(): void {
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
    }
  }

  private updateView(): void {
    const hasAnyAuthority = this.usrService.hasAnyAuthority(this.authorities);
    this.viewContainerRef.clear();
    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
