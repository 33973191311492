<div class="container-fluid ">
  <div class="row">
    <!-- Main Sidebar -->
    <div class="container-fluid">
      <div class="row">
        <div class="main-navbar sticky-top ">
          <!-- Main Navbar -->
          <div class="pos-f-tb menu-bar">
            <nav class="navbar navbar-dark menu-button ">
              <a class="lienTop" href="#">
                <div class="contentlienTop">
                  <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
                </div>
              </a>
              <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                      aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-times"></i>
                <i class="fas fa-bars"></i>
              </button>
            </nav>
            <div class="collapse" id="navbarToggleExternalContent">
              <div class="bg-white p-4 border-black">
                <h4 class="text-dark text-center"> Bonjour {{userName}} </h4>
                <ul class="nav nav--no-borders flex-column">
                  <li class="nav-item2" routerLink="/dashboard">
                    <span>Tableau de bord</span><span class="symbol">&#10095;</span>
                  </li>
                  <li class="nav-item2 " routerLink="/search">
                    <span>Mes recherches</span><span class="symbol">&#10095;</span>
                  </li>
                  <li class="nav-item2 " routerLink="/entreprise/weekdetails">
                    <span>Statistiques</span><span class="symbol">&#10095;</span>
                  </li>
                  <li class="nav-item2 selected" routerLink="/profile">
                    <span>Mon compte</span><span class="symbol">&#10095;</span>
                  </li>
                  <li class="nav-item2 " routerLink="/utilisateur" *ngIf="userData.content.profile=='Admin'">
                    <span>Utilisateurs</span><span class="symbol">&#10095;</span>
                  </li>
                  <li class="nav-item2 " (click)="logout()">
                    <span>Déconnexion</span><span class="symbol">&#10095;</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Main Sidebar -->
        <aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
          <div class="main-navbar">
            <nav class="navbar align-items-stretch  flex-md-nowrap p-0">
              <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
                <div class="">
                  <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px;"
                       src="../../assets/images/shards-dashboards-logo.svg" alt="Shards Dashboard">
                  <span class="d-none kairos d-md-inline ml-1">KAIROS</span>
                </div>
              </a>
            </nav>
          </div>
          <div class="nav-wrapper">
            <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
              <div class="">
                <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px;"
                     src="../../assets/images/shards-dashboards-logo.svg" alt="Shards Dashboard">
                <span class="d-none kairos d-md-inline ml-1">KAIROS</span>
              </div>
            </a>
            <div class="user">
              <div class="avatar-div" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                   aria-expanded="false">
                <img class="user-avatar mr-2" src="{{userData.content.profileUrl}}" alt="User Avatar">
              </div>
              <div class="userName d-none d-md-inline-block" *ngIf="userName">
                Bonjour {{userName}}</div>
              <div class="welcome">
                Bienvenue sur votre espace
              </div>
            </div>
            <ul class="nav nav--no-borders flex-column">
            <!--  <li class="nav-item dropdown" *appNgAuthority="['SuperAdmin', 'Admin']">
                        <a class="nav-link dropdown-toggle " data-toggle="dropdown" href="#"
                         role="button" aria-haspopup="true" aria-expanded="true">
                          <i class="material-icons">&#xE7FD;</i>
                          <span>GESTION UTILISATEURS</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-small">
                          <a class="dropdown-item " routerLink="/utilisateur/user">LISTES UTILISATEURS</a>
                          <a class="dropdown-item " routerLink="/utilisateur/create-user">CREER UTILISATEUR</a>
                        </div>
                  </li>
              -->
              <li class="nav-item " routerLink="/dashboard">
                <span>Tableau de bord</span>
              </li>
              <li class="nav-item " routerLink="/search">
                <span>Mes recherches</span>
              </li>
              <li class="nav-item " routerLink="/entreprise/weekdetails">
                <span>Statistiques</span>
              </li>
              <li class="nav-item select" routerLink="/profile">
                <span>Mon compte</span>
              </li>
              <li class="nav-item " routerLink="/utilisateur" *ngIf="userData && userData.content.profile=='Admin'">
                <span>Utilisateurs</span>
              </li>
              <li class="nav-item " (click)="logout()">
                <span>Déconnexion</span>
              </li>
            </ul>
          </div>
        </aside>
        <!-- End Main Sidebar -->
        <main class="main-content col-12 col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <!-- / .main-navbar -->
          <div class="main-content-container container-fluid  ">
            <div class="user-details__avatar mx-auto text-center">

              <img src="{{userData.content.profileUrl}}" alt="User Avatar">

            </div>
            <div class="text-center userName">
              <h4 class="text-center "
                  *ngIf="userData">{{userData.content.firstName}} {{userData.content.lastName}}</h4>

            </div>
            <div class="tab-div">
              <div class="tab">
                <button class="tablinks active" id="profilbtn" (click)="openCity( 'profil')">Mon profil</button>
                <button class="tablinks" id="abonnementbtn" (click)="openCity( 'abonnement')">Mon abonnement</button>
                <button class="tablinks" id="Parametresbtn" (click)="openCity( 'Parametres')">Paramètres</button>
                <button class="tablinks" id="Facturesbtn" (click)="openCity( 'Factures')">Factures</button>
                <button class="tablinks" id="Supportbtn" (click)="openCity( 'Support')">FAQ & Support</button>

              </div>
            </div>
            <div id="profil" class="tabcontent" style="display: block;">
              <div class="title-text">
                <h3 class="info-gene">Informations générales</h3>
              </div>
              <div class="general one">
                <div class="row contenu grandFormat">
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier">
                    Photo de profil
                  </div>
                  <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-9 premier">
                    Informations personnelles
                  </div>
                </div>
                <div class="row contenu">
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier small">
                    Photo de profil
                  </div>
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier">
                    <div class="user-details__avatar avatar2 mx-auto text-center">
                      <label for="upload" class="labelUpload">
                        <div class="profileDiv">
                          <img src="{{userData.content.profileUrl}}" alt="User Avatar" class="avatar-img">
                        </div>
                        <input type="file" id="upload" style="display:none" accept="image/*"
                               (change)="onFilePick(1,$event)">

                      </label>
                      <div class="super">
                        <button type="button" class="btn btn-sm btn-white supprimer" (click)="deleteFile(1)">
                          <i class="fas fa-trash"></i></button>
                      </div>

                    </div>
                  </div>
                  <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-9 premier">
                    <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-9 premier small">
                      Informations personnelles
                    </div>
                    <form class="myform">
                      <div class="row row-form">

                        <div class="form-group col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <label class="form-label">Statut</label>
                          <select class="form-control" [(ngModel)]="userData.content.civilite"
                                  [ngModelOptions]="{standalone: true}" placeholder="Dénomination">
                            <option value="Monsieur" selected>Monsieur</option>
                            <option value="Madame">Madame</option>
                            <option value="Mademoiselle">Mademoiselle</option>
                          </select>
                        </div>

                      </div>
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-4 col-md-4 col-xs-12 col-sm-6">
                          <label class="form-label">Nom</label>
                          <span>
                            <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder="Nom"
                                   [(ngModel)]="userData.content.firstName">
                          </span>
                        </div>

                        <div class="form-group  col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">Prenom</label>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="Prénom" [(ngModel)]="userData.content.lastName">
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group  col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <!--
                            <label for="exampleInputEmail1">Date de naissance</label>
                            <input type="date" class="form-control"  [ngModelOptions]="{standalone: true}" [(ngModel)]="userData.content.naissance"
                                placeholder ="Date de naissance">
                          -->
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="general">
                <div class="row contenu2">
                  <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-12 premier">
                    Adresse de contact
                    <form class="myform">
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">N° voie</label>
                          <span>
                            <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                   placeholder="N° voie" [(ngModel)]="userData.content.adresseVoie">
                          </span>
                        </div>
                        <div class="form-group  col col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <label class="form-label">Rue, chemin, avenue</label>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="Rue, chemin, avenue" [(ngModel)]="userData.content.adresseRue">
                        </div>
                      </div>
                      <div class="row row-form">
                        <div class="form-group col col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">Code postal</label>
                          <span>
                            <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                   placeholder="Code postal" [(ngModel)]="userData.content.codePostal">
                          </span>
                        </div>
                        <div class="form-group  col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">Ville</label>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="Ville" [(ngModel)]="userData.content.ville">
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier">
                    Numéro de contact
                    <form class="myform" #f="ngForm" [formGroup]="phoneForm">
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                          <label class="form-label">Mobile</label>
                          <span>
                            <ngx-intl-tel-input [cssClass]="'custom'" class="form-control"
                                                [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="CountryISO.France" id="phone" [maxLength]="15"
                                                [tooltipField]="TooltipLabel.Name"
                                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                [numberFormat]="PhoneNumberFormat.National" name="phone"
                                                formControlName="phone">
                            </ngx-intl-tel-input>
                            <!--
                              <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                                          placeholder ="telephone" [(ngModel)]="userData.content.phone">
                             -->
                          </span>
                        </div>
                      </div>
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                          <label class="form-label"></label>
                          <span>
                            <input type="text" class="form-control disab" disabled placeholder="">
                            <input type="text" class="form-control disab" disabled placeholder="">
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="text-center">
                  <button class="btn btn-accent save" (click)="updateUser()">ENREGISTRER</button>
                </div>
              </div>

              <!-- demarcation du bas  -->
              <div class="title-text">
                <h3 class="info-gene">Informations entreprise</h3>
              </div>

              <div class="general one">
                <div class="row contenu grandFormat">
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier">
                    Logo entreprise
                  </div>
                  <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-9 premier">
                    Informations de la société
                  </div>
                </div>
                <div class="row contenu">
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier small">
                    Logo entreprise
                  </div>
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier">

                    <div class="user-details__avatar avatar2 mx-auto text-center">
                      <label for="upload2" class="labelUpload">
                        <div class="profileDiv">
                          <img src="{{userData.content.companyUrl}}" alt="User Avatar" class="avatar-img">
                        </div>
                        <input type="file" id="upload2" style="display:none" accept="image/*"
                               (change)="onFilePick(2,$event)">
                      </label>
                      <div class="super">
                        <button type="button" class="btn btn-sm btn-white supprimer" (click)="deleteFile(2)">
                          <i class="fas fa-trash"></i></button>
                      </div>

                    </div>
                  </div>

                  <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-9 premier">
                    <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-9 premier small">Informations de la société</div>
                    <form class="myform">
                      <!--
                        <div class="row row-form">
                          <div class="form-group col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <select class="form-control" [(ngModel)]="userData.content.entrepriseActivite" [ngModelOptions]="{standalone: true}" placeholder="Activité">
                              <option value="" selected>Activité</option>
                              <option *ngFor="let datass of activites.content;let i = index" value={{datass.libelle}}>{{datass.libelle}}</option>
                            </select>
                          </div>
                        </div>
                      -->
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-4 col-md-4 col-xs-12 col-sm-6">
                          <label class="form-label">Nom de l’entreprise*</label>
                          <span>
                            <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                   placeholder="Nom de l’entreprise" [(ngModel)]="userData.content.entreprise">
                          </span>
                        </div>
                        <div class="form-group  col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">Code APE*</label>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="Code APE" [(ngModel)]="userData.content.entrepriseApe">
                        </div>
                      </div>
                      <div class="row row-form">
                        <div class="form-group  col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12 mb-5">
                          <label for="exampleInputEmail1">Date de création</label>
                          <input type="date" class="form-control" [ngModelOptions]="{standalone: true}"
                                 [(ngModel)]="userData.content.entrepriseCreation" placeholder="Date de naissance">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="general">
                <div class="row contenu2">
                  <div class="col col-12 col-md-9 col-lg-9 col-sm-12 col-xs-12 premier">
                    Adresse société
                    <form class="myform">
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">N° voie</label>
                          <span>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="N° voie" [(ngModel)]="userData.content.entrepriseVoie">
                        </span>
                        </div>
                        <div class="form-group col-12 col col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <label class="form-label">Rue, chemin, avenue</label>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="Rue, chemin, avenue" [(ngModel)]="userData.content.entrepriseRue">
                        </div>

                      </div>
                      <div class="row row-form">
                        <div class="form-group col col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">Code postal</label>
                          <span>
                        <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                               placeholder="Code postal" [(ngModel)]="userData.content.entrepriseCp">
                      </span>
                        </div>

                        <div class="form-group  col-12 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                          <label class="form-label">Ville</label>
                          <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                 placeholder="Ville" [(ngModel)]="userData.content.entrepriseVille">
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col col-12 col-md-3 col-lg-3 col-sm-12 col-xs-12 premier">
                    Numéro de contact
                    <form class="myform" #f="ngForm" [formGroup]="phoneForm2">
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                          <label class="form-label">Mobile</label>
                          <span>
                            <ngx-intl-tel-input [cssClass]="'custom'" class="form-control"
                                                [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="CountryISO.France" id="phone" [maxLength]="15"
                                                [tooltipField]="TooltipLabel.Name"
                                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                [numberFormat]="PhoneNumberFormat.National" name="phone"
                                                formControlName="phone">
                          </ngx-intl-tel-input>
                            <!--
                              <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder ="telephone" [(ngModel)]="userData.content.entreprisePhone">
                             -->
                          </span>
                        </div>
                      </div>
                      <div class="row row-form">
                        <div class="form-group col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
                          <label class="form-label"></label>
                          <span>
                          <input type="text" class="form-control disab" disabled placeholder="">
                        </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div id="societyError" class="text-danger text-center mt-2">
                  <div class="text-danger" *ngIf="societyError" id="societyError">
                    Remplir les champs obligatoires
                  </div>
                </div>
                <div class="text-center">
                  <button class="btn btn-accent save" (click)="updateUser()">ENREGISTRER</button>
                </div>
              </div>
            </div>


            <div id="abonnement" class="tabcontent text-center">
              <div class="conteuneur">
                <div class="abonnement">
                  <div class="premium" *ngIf="subscription && subscription.subscriptionId"><h3
                    class="">{{ subscription.subscriptionId.libelle}} </h3></div>
                  <div class="validite text-center"><h6 class="">Validité
                    : {{ subscription.paymentDate| date: 'dd/MM/yyyy '}}
                    au {{  this.userData.content.expiration| date: 'dd/MM/yyyy '}}  - Renouvellement automatique</h6>
                  </div>
                  <div class="validite text-center"
                       *ngIf="subscription.subscriptionId && !userData.content.subscriptionApe"><h6
                    class=" text-center">Détails
                    :  {{userData.content.subscriptionApe ? "(code NAF: " + userData.content.subscriptionApe + ")" : "" }} {{subscription.subscriptionId.detail}}</h6>
                  </div>
                  <div class="validite text-center"
                       *ngIf="subscription.subscriptionId && userData.content.subscriptionApe"><h6 class=" text-center">
                    Détails :  {{subscription.subscriptionId.detail.split(",")[0]}}({{userData.content.subscriptionApe}}
                    ), {{subscription.subscriptionId.detail.split(",")[1]}}</h6></div>
                  <div class="line">
                    <span class="left-title text-left">Type d’abonnement</span>
                    <span class="text-right right-title" *ngIf="subscription.annual">Annuel</span>
                    <span class="text-right right-title" *ngIf="!subscription.annual">Mensuel</span>

                  </div>
                  <div class="line">
                    <span class="left-title text-left" *ngIf="subscription.annual">Montant annuel (TTC)</span>
                    <span class="left-title text-left" *ngIf="!subscription.annual">Montant mensuel (TTC)</span>

                    <span class="text-right right-title" *ngIf="subscription.annual && subscription.subscriptionId">
                    {{ subscription.subscriptionId.annualAmount + subscription.subscriptionId.annualTva }} {{ subscription.subscriptionId.devise}}</span>
                    <span class="text-right right-title" *ngIf="!subscription.annual && subscription.subscriptionId">
                    {{ subscription.subscriptionId.amount + subscription.subscriptionId.tva}} {{ subscription.subscriptionId.devise}}</span>

                  </div>
                </div>
              </div>
              <span class="cancelAb" (click)="portail()">Annuler mon abonnement</span>
              <div *ngIf="subscription.subscriptionId?.libelle=='KAIROS'"><span class="cancelAb" (click)="portail2()"> changer mon code APE</span>
              </div>

            </div>

            <div id="Parametres" class="tabcontent">
              <div class="row">

                <div class="col col-12 col-lg-4 col-md-6 col-sm-12 mb-12 carre">
                  <div class="entreprises">Sécurité</div>

                  <div class="card card-small lo-stats h-100 contact">
                    <div class="card-body text-left">
                      <label class="form-label paramx">Mail</label>
                      <input type="email" class="form-control formidable" [ngModelOptions]="{standalone: true}"
                             placeholder="Email" [(ngModel)]="userData.content.email">


                    </div>
                    <div class="text-center">
                      <button type="button" class="btn btn-primary consulter2" (click)="updateUser()">MODIFIER</button>
                    </div>
                  </div>
                </div>
                <div class="col col-12 col-lg-4 col-md-6 col-sm-12 mb-12 carre">
                  <div class="entreprises">Mot de passe</div>

                  <div class="card card-small lo-stats h-100 contact">
                    <div class="card-body  text-left">
                      <label class="form-label">Mot de passe </label>
                      <input type="password" class="form-control formidable" [ngModelOptions]="{standalone: true}"
                             placeholder="Mot de passe" [(ngModel)]="userData.content.password">
                      <!--   <label class="form-label confirm paramx">Confirmation </label>
                        <input type="password" class="form-control formidable" [ngModelOptions]="{standalone: true}" placeholder ="Confirmez le mot de passe" [(ngModel)]="password2">
     -->

                    </div>
                    <div class="text-center">
                      <button type="button" [disabled]="!userData.content.password" class="btn btn-primary consulter2"
                              (click)="updatePassword()">MODIFIER
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col col-12 col-lg-4 col-md-6 col-sm-12 mb-12 carre">
                  <div class="entreprises">Carte de paiement</div>

                  <div class="card card-small lo-stats h-100 contact">
                    <div class="card-body text-left">
                      <label class="form-label paramx">Carte enregistrée</label>
                      <input type="text" class="form-control formidable" placeholder="Carte bancaire" disabled
                             value="{{carte}}">


                    </div>
                    <div class="text-center">
                      <button type="button" class="btn btn-primary consulter2 " (click)="portail()">modifier</button>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div id="Factures" class="tabcontent">
              <br>
              <br>
              <!-- <div class="text-center">
                <button  type="button" class="btn btn-primary" (click)="portail()">Voir mes factures </button>
              </div> -->
              <div class="title-text" *ngIf="factures">

                <h3 class="info-gene">Mes factures ({{factures.length}})</h3>
              </div>

              <table class="transaction-history" id="example" *ngIf="factures">
                <thead>
                <tr>
                  <!-- <th>#</th> -->
                  <th>Date</th>
                  <th>Nom de l’abonnement</th>
                  <th>Montant</th>
                  <th>Expiration</th>
                  <!--<th>Télécharger</th> -->


                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let data of factures" style="cursor: pointer;">
                  <td>{{data.paymentDate | date: 'dd/MM/yyyy '}}  </td>
                  <td>{{data.subscriptionId.libelle}} </td>

                  <td
                    *ngIf="!data.annual">{{data.subscriptionId.amount + data.subscriptionId.tva}} {{ subscription.subscriptionId.devise}}</td>
                  <td
                    *ngIf="data.annual">{{data.subscriptionId.annualAmount + data.subscriptionId.annualTva}} {{ subscription.subscriptionId.devise}}</td>

                  <td>    {{ data.expirationDate| date: 'dd/MM/yyyy '}}


                    <!--                     <button  type="button" class="btn btn-primary transp ">voir</button>
                      {{ subscription.expirationDate| date: 'dd/MM/yyyy '}}  -->

                  </td>
                  <td>
                    <button type="button" (click)="generatePdf(data)" class="btn btn-primary transp"> Télécharger
                    </button>

                  </td>

                </tr>


                </tbody>


              </table>
            </div>
            <div id="Support" class="tabcontent">
              <div class="row">
                <div class="col col-lg-6 col-md-6 col-sm-6 mb-12">
                  <div class="divmarg">FAQ</div>
                  <div class="card lo-stats h-100 faq contact">
                    <div class="card-body">
                      <h6 class="entText2 accordion bolder">Comment fonctionne la recherche avancée ?</h6>
                      <div class="panel">
                        <p class="texte">La recherche avancée vous permet d'effectuer votre recherche selon un ou
                          plusieurs
                          critères de recherche. Cette fonctionnalité vous permet ainsi d'affiner votre recherche dans
                          la base de données.
                          <br/> Pour effectuer une recherche sur l'un des critères, il suffit de remplir le champ
                          correspondant.
                          Le remplissage de plusieurs critères retournera un résultat basé sur le "ET" logique. Les
                          champs de recherche non remplis ne sont pas pris en compte dans la recherche.


                        </p>
                      </div>
                      <h6 class="entText2 accordion bolder">Comment fonctionne la recherche libre ?</h6>
                      <div class="panel">
                        <p> La recherche libre située à l'accueil vous permet d'effectuer une recherche sur un mot clé.
                          <br>
                          Le système recherche le mot clé saisi dans tous les critères des entreprises en base de
                          données.
                          Pour effectuer une recherche sur un critère précis vous devez aller dans l'onglet recherche
                          avancée.</p>
                      </div>
                      <h6 class="entText2 accordion bolder">Comment exporter le résultat de la recherche ?</h6>
                      <div class="panel">
                        <p>L'exportation de la liste des résultats n'est possible que si votre abonnement est KAIROS
                          Premium.
                          <br> Pour exporter, vous devez d'abord effectuer une recherche, lorsque les résultats de la
                          recherche s'affichent, vous avez la possibilité d'exporter la liste des résultats sous format
                          Excel.
                          Une fois le fichier téléchargé, vous pouvez l'ouvrir localement avec votre logiciel préféré.
                        </p>

                      </div>

                      <h6 class="entText2 accordion bolder">Bénéficiez de l’avis de nos experts</h6>
                      <div class="panel">
                        <p>Nos experts restent à votre disposition à tout moment afin de vous accompagner dans votre
                          processus de prise de décision.
                          Vous bénéficiez ainsi de leurs conseils avisés pour vous permettre de saisir la bonne affaire.
                          <br> Pour entrer en contact avec nos experts, il suffit de remplir le formulaire de contact et
                          poser votre question. </p>
                      </div>


                    </div>


                  </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-6 mb-12">

                  <div class="divmarg">Une question ? Un conseil ? Nous contacter</div>

                  <div class="card lo-stats h-100 contact">

                    <div class="card-body ">
                      <div class="form-group col-12 col-lg-12 col-md-12 col-sm-12">
                        <label class="form-label text-center type-question">Type de question</label>

                        <select class="form-control" [(ngModel)]="questionType" [ngModelOptions]="{standalone: true}"
                                placeholder="Dénomination">
                          <option value="autres" selected>Autres questions</option>
                          <option value="juridiques">Questions juridiques</option>


                        </select>
                      </div>
                      <textarea class="form-control" id="w3review" name="w3review" rows="5" [(ngModel)]="message"
                                [ngModelOptions]="{standalone: true}" placeholder="Comment pouvons-nous vous aider?">
                        </textarea>

                      <button (click)="contactUs()" [disabled]="!message" type="button"
                              class="btn btn-primary consulter2 envoyer">Envoyer
                      </button>
                    </div>

                  </div>
                </div>

              </div>
            </div>


          </div>

          <footer class="main-footer text-center border-top">

            <div class="copyright">

            <span class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
             <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
             <a href="https://www.kairos-opportunities.com/mentions-legales">Mentions Légales</a>
             <a
               href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">CGVU</a>
             <a href="https://www.kairos-opportunities.com/politique-de-confidentialite">Confidentialité</a>
             <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>

             <a routerLink="/dashboard" fragment="nousContacter">Contact</a>


           </span>
            </div>
          </footer>
        </main>
      </div>
      <ngx-loading [show]="loading"
                   [config]="{primaryColour: primaryColour, secondaryColour: secondaryColour, tertiaryColour: primaryColour, backdropBorderRadius: '3px'}"
                   [template]="customLoadingTemplate"></ngx-loading>
      <div id="snackbar">sauvegarde effectuée avec succès</div>
      <div id="snackbar3">Message envoyé avec succès</div>

      <div id="snackbar2">Le mot de passe doit contenir au minimum 6 caractères, des lettres minuscules, au moins 1
        lettre majuscule, 1 chiffre et 1 caractère spécial
      </div>
      <div class="imageCropper" id="imageCropper2" (blur)="hide()">
        <lib-cropper [imageFile]="fileInput" [options]="options" (fail)="onFail($event)" [(ngModel)]="data"
                     (fileChange)="onFileChange($event)"></lib-cropper>

        <div class="btndiv">
          <button (click)="crop()" type="button" class="btn btn-primary consulter2 uploadbtn">valider</button>
          <button (click)="hide()" type="button" class="btn btn-primary consulter2 cancelbtn">Annuler</button>

        </div>
      </div>

    </div>
