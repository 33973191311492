import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { Observable, ReplaySubject, of } from 'rxjs';
import { shareReplay, tap, catchError } from 'rxjs/operators';

const BASE_URL = 'https://app.kairos-opportunities.com/kairos/api/v1';
// const BASE_URL = 'http://34.163.92.238:8080/api/v1';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userIdentity;
  private authenticationState = new ReplaySubject<any>(1);
  private accountCache$?: Observable<any>;

 resourceUrl = BASE_URL + '/user/login';
  resourceUrl2 = BASE_URL + '/user/find';
  resourceUrl3 = BASE_URL + 'user/delete';
  resourceUrl4 = BASE_URL + '/user/change';
  resourceUrl5 = BASE_URL + '/user/all';
  resourceUrl50 = BASE_URL + '/user/media/delete';
  resourceUrl6 = BASE_URL + '/user/add';
  resourceUrl7 = BASE_URL + '/user/update';
  resourceUrl700 = BASE_URL + '/user/password';

  resourceUrl8 = BASE_URL + '/user/total';
  resource9 = BASE_URL + '/company/total';
  resource10 = BASE_URL + '/user/reset';
   resourceUrl10 =  BASE_URL + '/user/reset';
  resourceUrl11 = BASE_URL + '/user/password';
  resourceUrl12 = BASE_URL + '/user/checkOtp';
  resourceUrl13 = BASE_URL + '/user/otp';
 resource12 = BASE_URL + '/user/resetPassword';
 resourceUrl130 = BASE_URL + '/user/contact';


 resourceUrl400 = BASE_URL + '/susbcription/getSession';
 resourceUrl600 = BASE_URL + '/susbcription/payment';
 resourceUrl4000 = BASE_URL + '/susbcription/addApe';
 resourceUrl400001 = BASE_URL + '/susbcription/plans';

 resourceUrl2000 = BASE_URL + '/user/changeApe';
 resourceUrl20000 = BASE_URL + '/user/media';

 resourceUrl40000 = BASE_URL + '/user/subscription/current';
 resourceUrl4000010 = BASE_URL + '/user/stripe/portal';
 resourceUrl40000100 = BASE_URL + '/susbcription/oneShot';


 resourceUrl400000 = BASE_URL + '/user/myPayments';
 profilePictureUrl = BASE_URL + '/user/download';







//51.210.41.202:8082
  // resourceUrl =   'https://prod.veille.site:8443/api/v1/user/login';
  // resourceUrl2 = 'https://prod.veille.site:8443/api/v1/user/find';
  // resourceUrl3 = 'https://prod.veille.site:8443/api/v1/user/delete';
  // resourceUrl4 = 'https://prod.veille.site:8443/api/v1/user/change';
  // resourceUrl5 = 'https://prod.veille.site:8443/api/v1/user/all';
  // resourceUrl6 = 'https://prod.veille.site:8443/api/v1/user/add';
  // resourceUrl7 = 'https://prod.veille.site:8443/api/v1/user/update';
  // resourceUrl8 = 'https://prod.veille.site:8443/api/v1/user/total';
  // resource9 =    'https://prod.veille.site:8443/api/v1/company/total';
  // resourceUrl10 =   'https://prod.veille.site:8443/api/v1/user/reset';
  // resourceUrl11 = 'https://prod.veille.site:8443/api/v1/user/password';
  // resourceUrl12 = 'https://prod.veille.site:8443/api/v1/user/checkOtp'
  // resourceUrl13 = 'https://prod.veille.site:8443/api/v1/user/otp'
  // resource12 = 'https://prod.veille.site:8443/api/v1/user/resetPassword';

  public tokens:any;

  constructor(private http: HttpClient, private storage: LocalStorageService) { }

createUser(user:any){
  //const token = this.storage.retrieve('token');
  const httpOptions = {
  headers: new HttpHeaders({
   // 'Accept ' : 'application/json',
    'Content-Type':  'application/json',
     //Authorization:  'Bearer' + ' ' +token
  })
 }
  return this.http.post<any>(this.resourceUrl6, user, httpOptions);
}

  login(credentials: any){
    const httpOptions = {
      headers: new HttpHeaders({
       // 'Accept ' : 'application/json',
        'Content-Type':  'application/json',
      })
     }
    return this.http.post(this.resourceUrl, credentials, httpOptions);
  }

  contactUs(message,questionType){
    const token = this.storage.retrieve('token');
    let data={
      message:message,
      questionType:questionType

    }

    const httpOptions = {
      headers: new HttpHeaders({
       // 'Accept ' : 'application/json',
        'Content-Type':  'application/json',
        Authorization:  'Bearer' + ' ' +token
      })
     }
    return this.http.post(this.resourceUrl130, data, httpOptions);
  }
  findById(id){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl2}/${id}`, httpOptions);
  }

  checkOtp(code){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl12}/${code}`, httpOptions);
  }

  updateUser(id, user){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.put<any>(`${this.resourceUrl7}/${id}`, user, httpOptions);
  }
  updatePassword(password){
    let data={password:password};
    console.log(data)
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.put<any>(`${this.resourceUrl700}`, data, httpOptions);
  }
  del(id){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.delete(`${this.resourceUrl3}/${id}`, httpOptions);
  }



  changeState(id, status:boolean){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl4}/${id}/${status}`, httpOptions);
  }
  getPicture(picture){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.profilePictureUrl}/${picture}`, httpOptions);
  }
  getSession(id, userId,annual){
    //const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
          // Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl400}/${id}/${userId}/${annual}`, httpOptions);
  }
  getCurrentSub(){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl40000}`, httpOptions);
  }
  getAllSub(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }

return this.http.get(this.resourceUrl400000, httpOptions);
  }

  getPortal(){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl4000010}`, httpOptions);
  }
  getPortal2(userId){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl40000100}/${userId}`, httpOptions);
  }
    addApe(codeApe, userId){
    //const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
          // Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl4000}/${userId}/${codeApe}`, httpOptions);
  }

  getPlans(idPack:string){
    //const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
          // Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl400001}/${idPack}`, httpOptions);
  }

  changeApe(codeApe,session_id){
    const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get(`${this.resourceUrl2000}/${session_id}/${codeApe}`, httpOptions);
  }

  updatePayment(userId:any,session_id,status){
    //const token = this.storage.retrieve('token');
    let data={
      sessionId:session_id,
      status:status,
      userId:userId
    }
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       //Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.post<any>(this.resourceUrl600, data, httpOptions);
  }
  updateFile(file:any,format:number){
    const token = this.storage.retrieve('token');
    let data={
      file :file ,
      format :format ,
      Authorization:  'Bearer' + ' ' +token
        }
        let formData = new FormData();


formData.append('format',""+format);//append for data
formData.append('file',file);//append for file

    const httpOptions = {
/*       transformRequest: function(data, headersGetterFunction) {
        return data;
    }, */
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
     // 'Content-Type':   undefined,
       Authorization:  'Bearer' + ' ' +token
    }),
   }
    return this.http.post<any>(this.resourceUrl20000, formData, httpOptions);
  }
  deleteFile(file:string){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get(`${this.resourceUrl50}/${file}`, httpOptions);

  }
  getAll(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
return this.http.get(this.resourceUrl5, httpOptions);
  }

  RegenerateCode(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
return this.http.get(this.resourceUrl13, httpOptions);
  }



  authenticate(identity): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);

  }

  hasAnyAuthority(authorities: string[] | string): boolean {

    if (!this.userIdentity || !this.userIdentity.content.profile) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }

    return authorities.includes(this.userIdentity.content.profile);
  }

  identity(force?: boolean):any {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => {
          return of(null);
        }),
        tap((account: any | null) => {
          this.authenticate(account);

          // After retrieve the account info, the language will be changed to
          // the user's preferred language configured in the account setting
         /*  if (account && account.langKey) {
            const langKey = this.sessionStorage.retrieve('locale') || account.langKey;
            this.languageService.changeLanguage(langKey);
          } */

          /* if (account) {
            this.navigateToStoredUrl();
          } */
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

   fetch(): Observable<any> {
   const token = this.storage.retrieve('token');
        const httpOptions = {
        headers: new HttpHeaders({
         // 'Accept ' : 'application/json',
          'Content-Type':  'application/json',
           Authorization:  'Bearer' + ' ' +token
        })
       }
    return this.http.get<any>(this.resourceUrl2 ,httpOptions);
  }

  getNumberEntity(){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
   return this.http.get<any>(this.resourceUrl8 ,httpOptions);
  }

  resetPass(email){
    return this.http.get(`${this.resourceUrl10}/${email}`);
  }

  changePass(pass:any){
    const token = this.storage.retrieve('token');
    const httpOptions = {
    headers: new HttpHeaders({
     // 'Accept ' : 'application/json',
      'Content-Type':  'application/json',
       Authorization:  'Bearer' + ' ' +token
    })
   }
    return this.http.put<any>(this.resourceUrl11, pass, httpOptions);
  }

  changeResetPass(pass:any) {
    return this.http.put<any>(this.resource12, pass);
  }
}
