<div class="text-right supdiv" >
  <button type="button" class="btn btn-sm btn-primary suppresse"
(click)="dismiss()">

<i class="fas  fa-times" ></i></button></div>
  <div class="modal-body">
    <h1 class="mat-dialog-title dialogTitre text-center" >{{ title }}</h1>

    <div mat-dialog-content class="text-center">
      <p class="text-center mapped">  {{ message }}</p>
     
    </div> 
    <div class="text-center">
      <button type="button" class="btn btn-primary consulter2 m-0 butcon" (click)="accept()">{{ btnOkText }}</button>

    </div>

   
  </div>
 <!--  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
  </div> -->