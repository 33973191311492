import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgAuthorityDirective } from '../ng-authority.directive';


@NgModule({
  declarations: [NgAuthorityDirective],
  imports: [
    CommonModule
  ],
  exports:[NgAuthorityDirective]
})
export class SharedModule { }
