<div class="row h-100">
  <div class="main-navbar sticky-top ">
    <!-- Main Navbar -->

    <div class="pos-f-tb menu-bar">
      <nav class="navbar navbar-dark menu-button ">
                <a class="lienTop" href="#">
          <div class="contentlienTop">
            <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
          </div>
        </a>
        <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <i   class="fas fa-times" ></i>  
          <i  class="fas fa-bars" ></i>
 
        </button>
      </nav>
      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-white p-4 border-black">
          <ul class="nav nav--no-borders flex-column">             
            
            <li class="nav-item2 "  >
              <span><a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
              </span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " > 
              <span><a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a></span><span class="symbol">&#10095;</span>
            </li>
   
            <li class="nav-item2 " >
              <span><a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a></span><span class="symbol">&#10095;</span>
            </li>
            <li class="nav-item2 " >
              <span><a routerLink="/login"  class="selected">Se connecter</a></span><span class="symbol">&#10095;</span>
            </li> 
          </ul>      
      </div>
    </div>
  </div>
</div>
<main class="main-content col">

    <div class="main-content-container container-fluid px-4 my-auto h-100">
      
      <div class="top-element row">
      
        <div id="logo-container-mobile" class="col-lg-3 col-md-3 col-sm-4 col-3 logo-container middle">
          <div id="main-logo" class="navbar-header style-light">
            <a href="https://www.kairos-opportunities.com/" class="navbar-brand" data-minheight="20">
              <div class="logo-image main-logo logo-skinnable" data-maxheight="63" style="height: 63px;">
                <img src="../../assets/images/kairos.png
" alt="logo" width="645" height="188" class="img-responsive"></div></a>
          </div>
          <div class="mmb-container">
            <div class="mobile-additional-icons"></div>
            <div class="mobile-menu-button 1 mobile-menu-button-light lines-button x2">
              <span class="lines"></span></div></div>
        </div> 
       
        <div class=" kairos_menu col-lg-9 col-md-9 col-sm-12 col-12">
          <span  >
            <a href="https://www.kairos-opportunities.com/qui-sommes-nous/">Qui sommes-nous ?</a>
            <a href="https://www.kairos-opportunities.com/nos-tarifs/">Tarifs</a>
          <!--    <a href="#">Partenaires</a> -->
            <a href="https://www.kairos-opportunities.com/notre-expertise/">Expertises</a>
            <a href="https://www.kairos-opportunities.com/nos-analyses/">Nos analyses</a>
            <span  class="kairos_login">
              <a    class="account" href="https://www.kairos-opportunities.com/creer-un-compte" >Créer un compte</a>
              <a routerLink="/login"  class="selected">Se connecter</a>
        
            </span>
        
          </span>  
         </div>
  
        </div>        

    
        <div class="row contenu">
     
          <div  class="col col-12 col-lg-12 col-md-12 col-sm-12 ">
           
            
            <div class="card card-small lo-stats  macard" >
                <div class="card-body "> 
                  <div class="row m-0">
                    <div  class="col col-12 col-lg-6 col-md-6 col-sm-6 b-0">
                      
                      <div class=" lo-stats h-100 macard b-0 weektexts" >
                          <div class="card-body "> 
                             
                            <h6 class="entText2">Semaine {{weekNumber}} </h6>
                            <h2 class="entNumber2">{{data.content.total}}</h2>  
                          </div>
                          <h6 class="entText2"> {{data.content.rate}} </h6>

                        </div>
                  </div>
                  <div  class="col col-12 col-lg-6 col-md-6 col-sm-6 week-border">
                    
                    <div class="card card-small lo-stats h-100 macard weektextsdetails" >
                        <div class="card-body ">   

                          <h6  class="entText2 text-left" *ngFor="let weekData of data.content.categories">{{weekData.categorie}} : <b class="chiffre">{{weekData.total}}</b>  
                          </h6>
                        </div>
                      </div>
                </div>
                  </div>  
                </div>
              </div>
              <div class="focus">
                <div class="entreprises">Focus sur certains secteurs : </div>
              <div class="secteurs">
                <div *ngFor="let secteur of data.content.secteurs" class="secteur"> <div class="secteur_title">
                  <i class="fas fa-home secteur_icon" *ngIf="secteur.secteur=='Immobilier'" ></i>
                  <i class="fas fa-rocket secteur_icon" *ngIf="secteur.secteur=='Start-up'" ></i>
                  <i class="fas fa-industry secteur_icon" *ngIf="secteur.secteur=='Industrie'" ></i>
                  <i class="fas fa-plane secteur_icon plane" *ngIf="secteur.secteur=='Aéronautique'" ></i>
                  <div class="imgicon"> <img src="../../assets/icons/restaurant-svgrepo-com.svg" class="secteur_icon restau" *ngIf="secteur.secteur=='Restaurant'"> </div>
                  <div class="imgicon"> <img src="../../assets/icons/bed.png" class="secteur_icon restau" *ngIf="secteur.secteur=='Hôtellerie'"> </div>

                   {{secteur.secteur}}</div> 
                  <div class="secteur_content">
                    <h6  class="entText3 text-left" *ngFor="let weekData of secteur.categories">{{weekData.categorie}} : <b class="chiffre">{{weekData.total}}</b>  
                    </h6>
                  </div>  
                </div>
              </div>
              </div>
        </div>

        </div>
      <div class="copyright">
  
        <span    class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
         <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
         <a href="https://www.kairos-opportunities.com/mentions-legales">Mentions Légales</a>
         <a href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">CGVU</a>
         <a href="https://www.kairos-opportunities.com/politique-de-confidentialite">Confidentialité</a>
         <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>

         <a routerLink="/dashboard" fragment="nousContacter">Contact</a>
   
   
       </span>  
     </div>    
    </div>
  

  </main>

</div>
