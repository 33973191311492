import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user-service.service';


@Injectable({ providedIn: 'root' })
export class UserAccessAdminService implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private usrSer:UserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authorities = route.data['authorities'];
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  checkLogin(): Observable<boolean> {
    return this.usrSer.identity(true).pipe(
      map(account => {
        if (account) {
          console.log(account);
          return true;
        }
        this.router.navigate(['/login']);  
        return false; 
      })
    );
  }
}
