<div class="container-fluid">
  <div class="row">
    <!-- Main Sidebar -->
    <div class="main-navbar sticky-top ">
      <!-- Main Navbar -->

      <div class="pos-f-tb menu-bar">
        <nav class="navbar navbar-dark menu-button ">
          <a class="lienTop" href="#">
            <div class="contentlienTop">
              <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png"
                   alt="Shards Dashboard">
            </div>
          </a>
          <button class="navbar-toggler " type="button" data-toggle="collapse"
                  data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-times"></i>
            <i class="fas fa-bars"></i>

          </button>
        </nav>
        <div class="collapse" id="navbarToggleExternalContent">
          <div class="bg-white p-4 border-black">
            <h4 class="text-dark text-center"> Bonjour {{userName}} </h4>
            <ul class="nav nav--no-borders flex-column">
              <li class="nav-item2" routerLink="/dashboard">
                <span>Tableau de bord</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 selected" routerLink="/search">
                <span>Mes recherches</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " routerLink="/entreprise/weekdetails">
                <span>Statistiques</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " routerLink="/profile">
                <span>Mon compte</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " routerLink="/utilisateur" *ngIf="data.content.profile=='Admin'">
                <span>Utilisateurs</span><span class="symbol">&#10095;</span>
              </li>
              <li class="nav-item2 " (click)="logout()">
                <span>Déconnexion</span><span class="symbol">&#10095;</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
      <div class="">
        <nav class="navbar align-items-stretch  flex-md-nowrap p-0">
          <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
            <div class="">
              <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px;"
                   src="../../assets/images/shards-dashboards-logo.svg" alt="Shards Dashboard">
              <span class="d-none kairos d-md-inline ml-1">KAIROS</span>
            </div>
          </a>

        </nav>
      </div>

      <div class="nav-wrapper">
        <div class="user">

          <div class="avatar-div" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
               aria-expanded="false">
            <img class="user-avatar mr-2" src="{{data.content.profileUrl}}" alt="User Avatar">
          </div>
          <div class="userName d-none d-md-inline-block" *ngIf="userName">
            Bonjour {{userName}}</div>
          <div class="welcome">
            Bienvenue sur votre espace
          </div>

        </div>

        <ul class="nav nav--no-borders flex-column">
          <!--             <li class="nav-item dropdown" *appNgAuthority="['SuperAdmin', 'Admin']">
                        <a class="nav-link dropdown-toggle " data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="true">
                          <i class="material-icons">&#xE7FD;</i>
                          <span>GESTION UTILISATEURS</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-small">
                          <a class="dropdown-item " routerLink="/utilisateur/user">LISTES UTILISATEURS</a>
                          <a class="dropdown-item " routerLink="/utilisateur/create-user">CREER UTILISATEUR</a>
                        </div>
                      </li>  -->
          <li class="nav-item " routerLink="/dashboard">
            <span>Tableau de bord</span>


          </li>
          <li class="nav-item selected" routerLink="/search">
            <span>Mes recherches</span>


          </li>
          <li class="nav-item " routerLink="/entreprise/weekdetails">
            <span>Statistiques</span>


          </li>
          <li class="nav-item " routerLink="/profile">
            <span>Mon compte</span>


          </li>
          <li class="nav-item " routerLink="/utilisateur" *ngIf="data.content.profile=='Admin'">
            <span>Utilisateurs</span>


          </li>
          <li class="nav-item " (click)="logout()">
            <span>Déconnexion</span>


          </li>

          <!--             <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle " data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="true">
                            <i class="material-icons">&#xE7FD;</i>
                            <span>GESTION ENTREPRISES</span>
                          </a>
                          <div class="dropdown-menu dropdown-menu-small">
                            <a class="dropdown-item " routerLink="/entreprise/entreprise">LISTES ENTREPRISES</a>
                             <a class="dropdown-item " routerLink="/entreprise/create-entreprise">CREER ENTREPRISES</a>
                          </div>
                        </li> -->

        </ul>
      </div>
    </aside>
    <!-- End Main Sidebar -->
    <main class="main-content col-12  col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
      <div class="pagetitle"><span>Mes recherches</span></div>
      <!-- / .main-navbar -->

      <div class="main-content-container container-fluid px-4">
        <div class="row no-gutters h-100">

          <div class="auth-form__meta d-flex mt-4">


            <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
          </div>
          <div class="col-lg-12 col-md-12 col-12 ">
            <div class="auth-form__meta d-flex">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->
            </div>


            <div class="card">
              <div class="card-body mybody">
                <div class="trash">
                  <button type="button" class="btn btn-sm btn-white reset"
                          (click)="reset()"> Réinitialiser
                    <i class="fas fa-repeat"></i></button>
                </div>
                <h5 class="auth-form__title text-center recherche">Faire une recherche</h5>


                <form id="myForm">
                  <div class="row">
                    <div class="  col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                      <label for="exampleInputEmail1" class="mylab labelwide">Informations administratives</label>
                    </div>
                    <div class=" col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                      <label for="exampleInputEmail1" class="mylab labelwide">Activité</label>
                    </div>
                  </div>
                  <div class="row rowgroup">
                    <div class="form-group col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12 inputsmall">
                      <label for="exampleInputEmail1" class="mylab labelsmall">Informations administratives</label>

                      <input type="text" [disabled]="libelleApeDis" [(ngModel)]="codeApe" class="form-control"
                             id="codeApe"
                             [ngModelOptions]="{standalone: true}" placeholder="Code APE">
                    </div>
                    <div class="form-group   col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12 inputsmall">
                      <label for="exampleInputEmail1" class="mylab labelsmall">Activité</label>

                      <input type="text" class="form-control" [(ngModel)]="libelleApe"
                             [ngModelOptions]="{standalone: true}" placeholder="Activité">
                    </div>
                  </div>
                  <div class="row">
                    <div class="  col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                      <label for="exampleInputEmail1" class="mylab labelwide">Effectifs entreprises</label>
                    </div>
                    <div class=" col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                      <label for="exampleInputEmail1" class="mylab vide labelwide"></label>
                    </div>
                  </div>
                  <div class="row rowgroup">
                    <div class="form-group  col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12 associated">
                      <label for="exampleInputEmail1" class="mylab labelsmall">Effectifs entreprises</label>

                      <input type="number" class="form-control" [ngModelOptions]="{standalone: true}"
                             placeholder="Minimum" [(ngModel)]="effectif">
                      <span class="indic labelwide"> à </span>

                    </div>

                    <div class="form-group   col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">

                      <label for="exampleInputEmail1" class="mylab labelsmall">à</label>
                      <input type="number" class="form-control" [ngModelOptions]="{standalone: true}"
                             placeholder="Maximum" [(ngModel)]="effectifMax">
                    </div>
                    <!--  <div class="form-group  col-4 col-lg-4 col-md-4 col-xs-12 col-sm-12">
                       <label for="exampleInputEmail1" class="mylab"></label>
                       <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder ="code Postal" [(ngModel)]="codePostal">
                      </div> -->
                  </div>
                  <div class="row">
                    <div class=" col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12 bottom-align">
                      <label for="exampleInputEmail1" class="mylab labelwide ">Catégorie de jugement</label>
                    </div>
                    <div class="  col-12 col-lg-3 col-md-3 col-xs-6 col-sm-6">
                      <label for="exampleInputEmail1" class="mylab labelwide">Date de l’évènement</label>
                    </div>

                  </div>
                  <div class="row rowgroup">
                    <div class="  col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 labelsmall leftsmall">
                      <label for="exampleInputEmail1" class="mylab labelsmall leftsmall">Catégorie de jugement</label>
                    </div>
                    <div class="form-group  col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                      <select class="nature-select" size="4" [(ngModel)]="nature" [ngModelOptions]="{standalone: true}" multiple placeholder="Catégorie  du jugement" (ngModelChange)="update2()">
                        <option style="font-weight: 500" *ngFor="let nat of natures.content;let i = index" [value]="nat">{{nat}}</option>
                      </select>
                    </div>
                    <div class="  col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 labelsmall leftsmall">
                      <label for="exampleInputEmail1" class="mylab labelsmall leftsmall">Date de l’évènement</label>
                    </div>
                    <div class="form-group   col-6 col-lg-3 col-md-3 col-xs-6 col-sm-6 ">

                      <input type="date" name="begin" class="form-control" [(ngModel)]="decision"
                             [ngModelOptions]="{standalone: true}" placeholder="Date" value="" min="2020-01-01"
                             max="{{maxDate}}" (change)="saverange()">
                      <span class="indic"> au </span>

                    </div>
                    <div class="form-group   col-6 col-lg-3 col-md-3 col-xs-6 col-sm-6">

                      <input type="date" name="begin" class="form-control" [(ngModel)]="decisionMax"
                             (change)="saverangeMinDate()"
                             [ngModelOptions]="{standalone: true}" placeholder="Date" value="" min="{{minDate2}}"
                             max="{{maxDate2}}">
                    </div>
                  </div>
                  <div class="row">
                    <div class=" col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12 bottom-align">
                      <label for="exampleInputEmail1" class="mylab labelwide">Département</label>
                    </div>
                    <div class="  col-12 col-lg-3 col-md-3 col-xs-6 col-sm-6">

                      <label for="exampleInputEmail1" class="mylab labelwide">C.A. entreprises</label>
                    </div>
                    <div class="  col-12 col-lg-3 col-md-3 col-xs-6 col-sm-6">
                      <label for="exampleInputEmail1" class="mylab vide labelwide"></label>

                    </div>
                  </div>
                  <div class="row rowgroup">
                    <div class="  col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 labelsmall leftsmall">
                      <label for="exampleInputEmail1" class="mylab labelsmall leftsmall">Département</label>
                    </div>
                    <div class="form-group col-12 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                      <select class="form-control" [(ngModel)]="ville" [ngModelOptions]="{standalone: true}" placeholder="département" (ngModelChange)="update()">
                        <option value="" selected> Département</option>
                        <option *ngFor="let department of departements" [value]="department.departmentCode">{{department.departmentCode}}-{{department.departmentName}}</option>
                      </select>
                      <!-- <input type="email" class="form-control"  [ngModelOptions]="{standalone: true}" placeholder ="Département"> -->
                    </div>
                    <div class="  col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 labelsmall leftsmall">
                      <label for="exampleInputEmail1" class="mylab labelsmall leftsmall">C.A. entreprises</label>
                    </div>
                    <div class="form-group  col-6 col-lg-3 col-md-3 col-xs-6 col-sm-6 associated">

                      <input type="number" class="form-control" min="0" max="5000000000" (change)="saverange2()"
                             [ngModelOptions]="{standalone: true}" [(ngModel)]="chiffreAffaire" placeholder="Montant">
                      <span class="indic2"> €</span>

                      <span class="indic"> à </span>

                    </div>
                    <div class="form-group   col-6 col-lg-3 col-md-3 col-xs-6 col-sm-6">
                      <input type="number" class="form-control" min="0" max="{{maxCa}}"
                             [ngModelOptions]="{standalone: true}" placeholder="Montant"
                             [(ngModel)]="chiffreAffaireMax">
                      <span class="indic2"> €</span>

                    </div>
                  </div>

                  <div class="choix">

                    <span class="forgotten-pass">Inclure les entreprises qui n’ont pas publié leur C.A.</span>
                    <br class="labelsmall">

                    <input type="radio" name="entreprise" [ngModelOptions]="{standalone: true}" value="true"
                           [(ngModel)]="confidentialite"> oui
                    <input type="radio" name="entreprise" [ngModelOptions]="{standalone: true}" value="false"
                           [(ngModel)]="confidentialite" checked> non


                  </div>

                  <div class="res-div">

                    <button (click)="loadFavoris(codeApe,nature,libelleApe,chiffreAffaire,chiffreAffaireMax
                    ,ville,effectif,effectifMax,decision,decisionMax,confidentialite)"
                            class="btn btn-pill btn-accent rechercher">RECHERCHER
                    </button>
                  </div>
                </form>
              </div>
              <div class="card-footer border-top">
              </div>
            </div>
            <div class="auth-form__meta d-flex mt-4">
              <!-- <a class="ml-auto" routerLink="/change-pass">Changer mot de passe</a>   -->


            </div>

          </div>

        </div>

        <div class="entreprises" *ngIf="favoris.content[0]">Vos recherches</div>

        <div class="row" *ngIf="favoris">
          <div class="col col-lg-3 col-md-4 col-sm-6 mb-12 favoris" *ngFor="let data of favoris.content;let i = index">
            <div class="card card-small lo-stats h-100 last">
              <div class="card-body border-bottom">
                <div class="trash">
                  <button type="button" class="btn btn-sm btn-white suppress"
                          (click)="openConfirmationDialogDel('Confirmez SVP !', 'Voulez-vous vraiment supprimer?',data.id,i)">
                    <i class="fas fa-trash"></i></button>
                </div>
                <h6 class="entText3">{{data.name}}</h6>
                <h2 class="entNumber3">{{data.total}}</h2>
                <h6 class="entreprises2">Entreprises</h6>

                <button (click)="loadFavoris2(data.codeApe,data.nature,data.libelleApe,data.chiffreAffaire,data.chiffreAffaireMax,data.ville,data.effectif,
                data.effectifMax,data.decision,data.decisionMax,data.confidentialite )" type="button" class="btn  consult">
                  RELANCER
                </button>

              </div>


            </div>
          </div>

        </div>

      </div>
      <footer class="main-footer text-center border-top">
        <div class="copyright">

            <span class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
             <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
             <a href="https://www.kairos-opportunities.com/mentions-legales">Mentions Légales</a>
             <a
               href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">CGVU</a>
             <a href="https://www.kairos-opportunities.com/politique-de-confidentialite">Confidentialité</a>
             <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>

             <a routerLink="/dashboard" fragment="nousContacter">Contact</a>


           </span>
        </div>
      </footer>
    </main>
  </div>
  <div id="snackbar">opération effectuée avec succès</div>
  <div id="snackbar2">le chiffre d'affaire maximal doit être supérieur au chiffre d'affaire minimal</div>

</div>
