<div class="container-fluid">
    <div class="row">
      <div class="main-navbar sticky-top ">
        <!-- Main Navbar -->

        <div class="pos-f-tb menu-bar">
          <nav class="navbar navbar-dark menu-button ">
                 <a class="lienTop" href="#">
          <div class="contentlienTop">
            <img id="main-logo" class="imageTop" style="max-height: 42px;" src="../../assets/images/kairos.png" alt="Shards Dashboard">
          </div>
        </a>
            <button class="navbar-toggler "  type="button"  data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <i   class="fas fa-times" ></i>
              <i  class="fas fa-bars" ></i>

            </button>
          </nav>
          <div class="collapse" id="navbarToggleExternalContent">
            <div class="bg-white p-4 border-black">
              <h4 class="text-dark text-center"> Bonjour {{userName}} </h4>
              <ul class="nav nav--no-borders flex-column">
                <li class="nav-item2 selected" routerLink="/dashboard" >
                  <span>Tableau de bord</span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " routerLink="/search" >
                  <span>Mes recherches</span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " routerLink="/entreprise/weekdetails" >
                  <span>Statistiques</span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 "  routerLink="/profile">
                  <span>Mon compte</span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " routerLink="/utilisateur" *ngIf="data.content.profile=='Admin'">
                  <span>Utilisateurs</span><span class="symbol">&#10095;</span>
                </li>
                <li class="nav-item2 " (click)="logout()" >
                    <span>Déconnexion</span><span class="symbol">&#10095;</span>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
      <!-- Main Sidebar -->
      <aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
        <div class="main-navbar">
          <nav class="navbar align-items-stretch  flex-md-nowrap p-0">
          </nav>
        </div>
        <div class="nav-wrapper">
          <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
            <div class="">
              <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px;" src="../../assets/images/shards-dashboards-logo.svg" alt="Shards Dashboard">
              <span class="d-none kairos d-md-inline ml-1">KAIROS</span>
            </div>
          </a>
          <div class="user">
              <div class="avatar-div" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                <img class="user-avatar mr-2" src="{{data.content.profileUrl}}" alt="User Avatar">
              </div>
                <div class="userName d-none d-md-inline-block" *ngIf="userName">
                  Bonjour {{userName}}</div>
                  <div class="welcome">
                  Bienvenue sur votre espace
                </div>
            </div>

          <ul class="nav nav--no-borders flex-column">

            <li class="nav-item selected" routerLink="/dashboard" >
              <span>Tableau de bord</span>
            </li>

            <li class="nav-item " routerLink="/search" >
              <span>Mes recherches</span>
            </li>
            <li class="nav-item " routerLink="/entreprise/weekdetails" >
              <span>Statistiques</span>
            </li>
            <li class="nav-item "  routerLink="/profile">
              <span>Mon compte</span>
            </li>
            <li class="nav-item " routerLink="/utilisateur" *ngIf="data.content.profile=='Admin'">
              <span>Utilisateurs</span>
            </li>
            <li class="nav-item " (click)="logout()">
              <span>Déconnexion</span>
            </li>
          </ul>
        </div>
      </aside>
      <!-- End Main Sidebar -->
      <main class="main-content col-12  col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
 <!-- / .main-navbar -->
        <div>
          <div class="pagetitle"> <span>Tableau de bord</span></div>
          <form class="example-form">
            <div class="searchform row">
              <div class="search row">
              <input type="text" class="rechercher col col-10 col-lg-10 col-md-10" [(ngModel)]="freesearch"   [ngModelOptions]="{standalone: true}" placeholder="Recherchez une entreprise : code APE, Activité, Nature de jugement">
              <button type="button" class="btn supprimer  col col-2 col-lg-2 col-md-2"
                (click)="freeSearch()">
                <i class="fas fa-search" ></i></button>
            </div>
            <button type="button" class="btn supprimer rechercheAvance"
            routerLink="/search">
                Recherche avancée</button>
            </div>
          </form>

        </div>
        <div class="main-content-container container-fluid px-4">
          <div class="row">
            <div class="col col-12 col-lg-4 col-md-12 col-sm-12 special" >
              <div class="entreprises">Entreprises</div>
                <div class="card card-small lo-stats h-100" >
                    <div class="card-body ">
                      <h6 class="entText">Toutes les entreprises</h6>
                      <h2 class="entNumber">{{entTotal}}</h2>

                      <button routerLink="/entreprise/entreprise" type="button" class="btn consult">Consulter</button>

                    </div>


                  </div>
            </div>
            <div  class="col col-12 col-lg-8 col-md-12 col-sm-12 ">
              <div class="entreprises">Chiffres de la semaine</div>

              <div class="card card-small lo-stats h-100 macard" >
                  <div class="card-body ">
                    <div class="row m-0">
                      <div  class="col col-12 col-lg-6 col-md-6 col-sm-6 b-0">

                        <div class=" lo-stats h-100 macard b-0 weektexts" >
                            <div class="card-body ">

                              <h6 class="entText2">Semaine {{weekNumber}} - {{year}} </h6>
                              <h2 class="entNumber2">{{weekTotal}}</h2>

                            </div>
                            <button routerLink="/entreprise/entreprise/week" type="button" class="btn btn-primary consulter2 m-0">Consulter</button>
                          </div>
                    </div>
                    <div  class="col col-12 col-lg-6 col-md-6 col-sm-6 week-border">
                      <div class="card card-small lo-stats h-100 macard weektextsdetails" >
                          <div class="card-body" style="display: flex; flex-direction: column; justify-content: space-between">
                            <h6 class="entText2 text-left">{{weeks[0].categorie}} : <b class="chiffre">{{weeks[0].total}}</b></h6>
                            <h6 class="entText2 text-left">{{weeks[1].categorie}} : <b class="chiffre">{{weeks[1].total}}</b></h6>
                            <h6 class="entText2 text-left">{{weeks[2].categorie}} : <b class="chiffre">{{weeks[2].total}}</b></h6>
                            <h6 class="entText2 text-left">{{weeks[3].categorie}} : <b class="chiffre">{{weeks[3].total}}</b></h6>
                            <!--  <h6 class="entText2 text-left" *ngFor="let data of weeks">{{data.categorie}} : <b class="chiffre">{{data.total}}</b></h6> -->
                          </div>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
          </div>

          </div>
          <div>
          <div *ngIf="favoris && favoris.content[0]" class="entreprises" style="margin-top: 30px; margin-bottom:0;padding-top: 15px;">Vos dernières recherches
            <button routerLink="/search" type="button" class="btn suppress2">voir tout</button></div>
     </div>
          <div class="row" *ngIf="favoris && favoris.content">

            <div class="col col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-12 favoris"  *ngFor="let data of favoris.content.slice(0, 4);let i = index" >
                <div class="card card-small lo-stats h-100 last" >
                    <div class="card-body ">
                      <button type="button" class="btn btn-sm btn-white suppress"
                      (click)="openConfirmationDialogDel('Confirmez SVP !', 'Voulez-vous vraiment supprimer?',data.id,i)">
                      <i class="fas fa-trash" ></i></button> <h6 class="entText3">{{data.name}}</h6>
                      <h2 class="entNumber3">{{data.total}}</h2>
                      <h6 class="entreprises2">Entreprises</h6>

                      <button (click)="loadFavoris(data.codeApe,data.nature,data.libelleApe,data.chiffreAffaire,data.chiffreAffaireMax
                      ,data.ville,data.effectif,data.effectifMax,data.decision,data.decisionMax,data.confidentialite )" type="button" class="btn  consult relancer">RELANCER</button>

                    </div>

                  </div>
            </div>

          </div>
          <div class="minimaldiv" *ngIf="favoris && favoris.content">
            <button routerLink="/search" type="button" class="btn suppress2 minimal">voir tout</button>
          </div>
          <div class="row no-margin" >
            <div class="col col-12 col-lg-6 col-md-6 col-sm-12 mb-12 " >
              <div class="entreprises">FAQ</div>
            </div>
            <div class="col col-12 col-lg-6 col-md-6 col-sm-12 mb-12  hidesmall" >
              <div class="entreprises">Une question ? Un conseil ? Nous contacter</div>
            </div>

          </div>
          <div class="row" id="nousContacter">
            <div class="col col-12 col-lg-6 col-md-6 col-sm-12 mb-12 special" >

                <div class="card card-small lo-stats h-100 faq" >
                    <div class="card-body ">
                      <h6 class="entText2 accordion bolder">Comment fonctionne la recherche avancée ?</h6>
                      <div class="panel">
                        <p class="texte">La recherche avancée vous permet d'effectuer votre recherche selon un ou plusieurs critères de recherche. Cette fonctionnalité vous permet ainsi d'affiner votre recherche dans la base de données.
                          <br/> Pour effectuer une recherche sur l'un des critères, il suffit de remplir le champ correspondant. Le remplissage de plusieurs critères retournera un résultat basé sur le "ET" logique. Les champs de recherche non remplis ne sont pas pris en compte dans la recherche.
                         </p>
                      </div>
                      <h6 class="entText2 accordion bolder"> Comment fonctionne la recherche libre ?</h6>
                      <div class="panel">
                        <p> La recherche libre située à l'accueil vous permet d'effectuer une recherche sur un mot clé. <br>
                           Le système recherche le mot clé saisi dans tous les critères des entreprises en base de données. Pour effectuer une recherche sur un critère précis vous devez aller dans l'onglet recherche avancée.</p>
                      </div>
                      <h6 class="entText2 accordion bolder">Comment exporter le résultat de la recherche ?</h6>
                      <div class="panel">
                        <p>L'exportation de la liste des résultats n'est possible que si votre abonnement est KAIROS Premium.
                          <br> Pour exporter, vous devez d'abord effectuer une recherche, lorsque les résultats de la recherche s'affichent, vous avez la possibilité d'exporter la liste des résultats sous format Excel.
                          Une fois le fichier téléchargé, vous pouvez l'ouvrir localement avec votre logiciel préféré. </p>
                      </div>

                      <h6 class="entText2 accordion bolder">Bénéficiez de l’avis de nos experts</h6>
                      <div class="panel">
                        <p>Nos experts restent à votre disposition à tout moment afin de vous accompagner dans votre processus de prise de décision.
                          Vous bénéficiez ainsi de leurs conseils avisés pour vous permettre de saisir la bonne affaire.
                         <br> Pour entrer en contact avec nos experts, il suffit de remplir le formulaire de contact et poser votre question. </p>
                      </div>
                    </div>
                  </div>
            </div>
            <div  class="col col-12 col-lg-6 col-md-6 col-sm-12 mb-12 special">


                <div class="entreprises showsmall">Une question ? Un conseil ? Nous contacter</div>

              <div class="card card-small lo-stats h-100 contact" >
                  <div class="card-body ">
                    <div class="form-group">
                      <label class="form-label text-left">Type de question</label>
                      <select class="form-control"  [(ngModel)]="questionType" [ngModelOptions]="{standalone: true}" placeholder ="denomination">
                        <option value="autres" selected>Autres questions</option>
                        <option value="juridiques" >Questions juridiques</option>
                      </select>
                    </div>
                    <textarea class="form-control" id="w3review" name="w3review" rows="5" [(ngModel)]="message"   [ngModelOptions]="{standalone: true}" placeholder="Comment pouvons-nous vous aider?">
                      </textarea>
                    <button (click)="contactUs()"  [disabled]="!message" type="button" class="btn btn-primary consulter2">Envoyer</button>
                  </div>
                </div>
          </div>
          </div>
        </div>
        <footer class="main-footer text-center border-top">
        <div class="copyright">
           <span    class=" kairos_menus col-md-8 col-sm-12 col-xs-12">
            <a href="https://www.kairos-opportunities.com">©  KAIROS V2.2  &nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a href="https://www.kairos-opportunities.com/mentions-legales">Mentions Légales</a>
             <a href="https://www.kairos-opportunities.com/conditions-generales-dutilisation-et-conditions-generales-de-vente">CGVU</a>
            <a href="https://www.kairos-opportunities.com/politique-de-confidentialite">Confidentialité</a>
            <a routerLink="/dashboard" fragment="nousContacter">FAQ</a>
            <a routerLink="/dashboard" fragment="nousContacter">Contact</a>
          </span>
        </div>
       </footer>
      </main>
    </div>
    <div id="snackbar">Votre message a été envoyé</div>
  </div>
